/**
 * Created by z211567 on 26.05.2015.
 */

$.fn.evenDigitCheckPlugin = function (options) {
	var pluginName = "evenDigitCheckPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
	}, options);

	this.resetState = function(inputElement){
		$("#skz").html("");

		var myInput= $(inputElement);

		if(myInput.val().length === parseInt(myInput.attr("maxlength"))){

			if(myInput.attr("id") === "nummerBlock_1"){
				$("#nummerBlock_2").trigger('focus');
			}else if (myInput.attr("id") === "nummerBlock_2"){
				$("#nummerBlock_3").trigger('focus');
			}else if(myInput.attr("id") === "nummerBlock_3"){
				this.skzAction();
			}
		}

	};

	this.resetFields = function(){
		$("#nummerBlock_1").val("");
		$("#nummerBlock_2").val("");
		$("#nummerBlock_3").val("");
		$("#skz").html("");
	};

	this.skzAction = function(){
		var skz = $("#skz");
		var nummerBlock_1 = $("#nummerBlock_1");
		var nummerBlock_2 = $("#nummerBlock_2");
		var nummerBlock_3 = $("#nummerBlock_3");
		var isError = false;
		var skzSum = 0;
		var quersumTop=0;

		var number = nummerBlock_1.val()+nummerBlock_2.val()+nummerBlock_3.val();
		if(!isNaN(number) && (number.length === 7 || number.length === 11)){
			var sum = "";

			for(var i = 0; i < number.length; i++){
				var value = parseInt(number.charAt(i));

				if(i%2 === 0){
					sum += "" + value*2;
				} else{
					sum += "" + value;
				}
			}

			for(var i = 0; i < sum.length; i++){
				skzSum += parseInt(sum.charAt(i));
			}

			quersumTop = Math.ceil(skzSum/10) * 10;
		} else{
			isError=true
		}

		if(!isError) {
			skz.html(quersumTop - skzSum);
		} else{
			skz.html("Fehler");
		}
	};

};