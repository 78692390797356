
$.fn.contactUsPlugin = function (options) {
	var pluginName = "contactUsPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
		// color: "#556b2f",
		// backgroundColor: "white"
	}, options);
	// Do something to each element here.

	var $this,
		ajaxUrl,
		dataCombinations,
		dataIndustries,
		activeIndustry,
		activeCountry,
		generatedLink;

	return this.each(function () {
		$this = $(this);
		ajaxUrl = $(this).attr('data-url');
		activeCountry = $('select[name="ntr-countries"').val();
		activeIndustry = $('select[name="ntr-industries"').val();
		
		sortOptions($('#ntr-industries'));
		sortOptions($('#ntr-countries'));

		getData(ajaxUrl);
		initEventlistener();
	});

	function sortOptions($element) {
		var options   = $element.find('option.sortable'),
			n_options = options.length,
			temp = [],
			parts,
			i;

		for(i = n_options; i --;) {
			temp[i] = options[i].text + "," + options[i].value + "," + options[i].selected; 
		}

		temp.sort();

		for(i = n_options; i --;) {
			parts = temp[i].split(',');

			options[i].text  = parts[0];
			options[i].value = parts[1];

			if(parts[2] == "true") {
				$(options[i]).attr("selected", "selected");
			} else {
				$(options[i]).removeAttr('selected');
			}
		}
	}
		
	function getData(url) {
		$.get(url, function (data) {
			var dataJSON = JSON.parse(data)
			

			if(dataJSON.combinations) {
				dataCombinations = dataJSON.combinations;
			}

			if(dataJSON.industries.length > 0) {
				dataIndustries = dataJSON.industries;
			}
			
		});
	}

	function initEventlistener() {
		$('select[name="ntr-industries"').on('change', function() {
			activeIndustry = $(this).val();

			generateLink();
		});

		$('select[name="ntr-countries"').on('change', function() {
			activeCountry = $(this).val();
			generateLink();
		});
	}

	function generateLink() {
		if(activeCountry && activeIndustry) {
			generatedLink = dataCombinations[activeCountry].industries[activeIndustry];

			$this.attr('action', generatedLink);
			$('#ntr-button').removeAttr('disabled');
		}
	}

	
	
};