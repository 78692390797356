//(function ($) {
	$.fn.popoverPlugin = function (options) {
		var pluginName = "popoverPlugin";

		// default options.
		var $html = $("html");
		var lang = $html.attr("lang") != 'undefined' ?  $html.attr("lang") : "de";
		var defaults = {
			autoOpen: false,
			appendTo: "body",
			buttons: [],
			blurContainer: [],
			closeOnEscape: true,
			modal: false,
			disclaimer: false,
			title: null,
			minHeight: 250,
			minWidth: 190,
			height: null, // max = 640
			width: null, // max = 500
			open: null,
			beforeClose: null,
			close: null,
			language: {
				sAccept: "Akzeptieren",
				sDecline: "Ablehnen",
				sClose: "Schließen",
				acceptButtonText: "Haftungsausschluss akzeptieren",
				declineButtonText: "Rücknahme des Haftungsausschlusses"
			},
			inTvSite: false,
			tvVideoLink: null
		};

		//language settings
		if(lang == 'en') {
			defaults.language = {
				sAccept: "Accept",
				sDecline: "Decline",
				sClose: "Close",
				acceptButtonText: "Accept disclaimer",
				declineButtonText: "Decline disclaimer"
			}
		}
		var settings = $.extend({}, defaults, options);

		return this.each(function () {
            //if (!$.data(this, 'plugin_', pluginName)) {
			//	$.data(this, 'plugin_' + pluginName);
			//}

			var $popElem = $(this);
			var $origParent = $(this).parent();

			if(settings.disclaimer) {
				var $body = $("body");
				var $appendEl = $(settings.appendTo);

				//creating overlay container
				var $disclaimerOverlay = $(document.createElement("div")).attr("id", "disclaimer-overlay").addClass("disclaimer-overlay");
				// creating buttons
				var $disclaimerButtons = $(document.createElement("div")).attr("id", "md-disclaimer-buttons").addClass("md-disclaimer-buttons");
				var $buttonDecline = $(document.createElement("button")).attr("id", "md-disclaimer-decline").attr("aria-label", settings.language.declineButtonText).addClass("popover-button").append(
					document.createTextNode(settings.language.sDecline)
				);
				var $buttonAccept = $(document.createElement("button")).attr("id", "md-disclaimer-accept").addClass("popover-button").attr("aria-label", settings.language.acceptButtonText).append(
					document.createTextNode(settings.language.sAccept)
				);
				$disclaimerButtons.append($buttonDecline);
				$disclaimerButtons.append($buttonAccept);

				//generate the popover
				$popElem.append($disclaimerButtons);
				$body.addClass("disclaimer-ready");
				if(settings.blurContainer.length > 0) {
					$.each(settings.blurContainer, function(index, value){
						$(value).addClass("disclaimer-blur");
					});
				}
				if ($('.disclaimer-overlay').length == 0) {
					$body.append($disclaimerOverlay);
				}

				$body.addClass("disclaimer-active");
				$popElem.attr("role", 'alertdialog');
				$popElem.attr("aria-labelledby", 'disclaimer-title');
				$popElem.attr("aria-modal", 'true');
				$popElem.addClass("disclaimer-widget");
				$appendEl.append($popElem);

				$popElem.trigger('focus');

				function setDisclaimerDimensions() {
					var elemWidth = $(window).width();
					var  elemHeight =  $(window).height();

					if(elemWidth >= 568 && elemHeight >= 600) {
						if($(window).width() > $(window).height()) {
							elemWidth = 500;
							elemHeight = 375;
						} else {
							elemWidth = 568;
							elemHeight = 600;
						}
					}
					$popElem.css({
						'width': (elemWidth - 20)  + 'px',
						'height': (elemHeight - 20) + 'px',
						'margin-left': '-' + ((elemWidth / 2) - 10) + 'px',
						'margin-top': '-' + ((elemHeight / 2) - 10) + 'px'
					});
				}
				setDisclaimerDimensions();

				//Sets the rest of the page as not "tabable", and only the disclaimer "tabable"
				$("body *[tabindex='0']").attr("data-tabindex", "0");
				$("body *[tabindex='-1']").attr("data-tabindex", "-1");
				$("body *").not("#disclaimer, #disclaimer *").attr("tabindex", "-1");

				$("body *[aria-hidden='true']").not("#disclaimer, #disclaimer *").attr("data-aria-hidden", "true");
				$("body *").not("#disclaimer, #disclaimer *").attr("aria-hidden", "true");

				// click handling
				$buttonDecline.on('click', function(e) {
					/*
					$disclaimerOverlay.remove();
					$disclaimerButtons.remove();
					$body.removeClass("disclaimer-ready");
					$body.removeClass("disclaimer-active");
					$.each(settings.blurContainer, function(index, value){
						$(value).removeClass("disclaimer-blur");
					});
					$popElem.removeClass("disclaimer-widget");
					$popElem.removeAttr("style");
					$origParent.prepend($popElem);
					*/

					if (e.preventDefault) {
						e.preventDefault();
					} else {
						e.returnValue = false;
					}

					if(settings.inTvSite){
						$disclaimerOverlay.remove();
						$disclaimerButtons.remove();
						$body.removeClass("disclaimer-ready");
						$body.removeClass("disclaimer-active");
						$.each(settings.blurContainer, function(index, value){
							$(value).removeClass("disclaimer-blur");
						});
						$popElem.removeClass("disclaimer-widget");
						$popElem.removeAttr("style");
					} else {
						var currentUrl = window.location.href;
						var parentUrl = currentUrl.substring(0, currentUrl.lastIndexOf("/"));

						window.location.href = parentUrl;
					}

					//Sets the disclaimer as "non-tabable" and the rest of the page as "tabable" as before
					$("body *").not("#disclaimer, #disclaimer *").removeAttr("tabindex");
					$("body *[data-tabindex='0']").attr("tabindex", "0").removeAttr("data-tabindex");
					$("body *[data-tabindex='-1']").attr("tabindex", "-1").removeAttr("data-tabindex");

					$("body *").not("#disclaimer, #disclaimer *").removeAttr("aria-hidden");
					$("body *[data-aria-hidden='true'").not("#disclaimer, #disclaimer *").attr("aria-hidden", "true");
					$("body *[data-aria-hidden='true'").not("#disclaimer, #disclaimer *").removeAttr("data-aria-hidden");

				});
				$buttonAccept.on('click', function(e) {
					if (e.preventDefault) {
						e.preventDefault();
					} else {
						e.returnValue = false;
					}

					if(settings.inTvSite){
						window.open(settings.tvVideoLink, '_blank');
						$disclaimerOverlay.remove();
						$disclaimerButtons.remove();
						$body.removeClass("disclaimer-ready");
						$body.removeClass("disclaimer-active");
						$.each(settings.blurContainer, function(index, value){
							$(value).removeClass("disclaimer-blur");
						});
						$popElem.removeClass("disclaimer-widget");
						$popElem.removeAttr("style");
					} else {
						$disclaimerOverlay.remove();
						$disclaimerButtons.remove();
						$body.removeClass("disclaimer-ready");
						$body.removeClass("disclaimer-active");
						$.each(settings.blurContainer, function(index, value){
							$(value).removeClass("disclaimer-blur");
						});
						$popElem.removeClass("disclaimer-widget");
						$popElem.removeAttr("style");
						//$origParent.prepend($popElem);
						$popElem.remove();
					}

					//Sets the disclaimer as "non-tabable" and the rest of the page as "tabable" as before
					$("body *").not("#disclaimer *").removeAttr("tabindex");
					$("body *[data-tabindex='0']").attr("tabindex", "0").removeAttr("data-tabindex");
					$("body *[data-tabindex='-1']").attr("tabindex", "-1").removeAttr("data-tabindex");

					$("body *").not("#disclaimer, #disclaimer *").removeAttr("aria-hidden");
					$("body *[data-aria-hidden='true'").not("#disclaimer, #disclaimer *").attr("aria-hidden", "true");
					$("body *[data-aria-hidden='true'").not("#disclaimer, #disclaimer *").removeAttr("data-aria-hidden");

				});

				$(document).on('keydown', function(event) {
					var KEY_TAB = 9;

					if($('body').hasClass('disclaimer-active')) {
						if (event.keyCode == KEY_TAB && event.shiftKey) {
					        if(document.activeElement == buttonDecline) {
					            event.preventDefault();
					            $buttonAccept.trigger('focus');
					        }
					    } else if (event.keyCode == KEY_TAB) {
					        if(document.activeElement == buttonAccept) {
					            event.preventDefault();
					            $buttonDecline.trigger('focus');
					        }
					    }
					}
				});
			}

			$(window).on('resize', function () {
				setDisclaimerDimensions();
			});

		});
	};
//})(jQuery);
