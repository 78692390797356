/**
 * Created by z211567 on 26.05.2015.
 */

$.fn.mashupSocialMediaCheckPlugin = function (options) {
	var pluginName = "mashupSocialMediaCheckPlugin";


	// default options.
	var settings = $.extend({
		// These are the defaults.

	}, options);
	// Do something to each element here.

	function activateFacebook() {
		var mupFacebookContent = $(".mup-facebook-content");

		if (mupFacebookContent.length == 0) {
			mupFacebookContent = $("<div class=\"mup-facebook-content\"></div>");
			$(".mup-facebook").prepend(mupFacebookContent);


			mupFacebookContent.html(
				"<script>(function(d, s, id) {" +
				"var js, fjs = d.getElementsByTagName(s)[0];" +
				"if (d.getElementById(id)) return;" +
				"js = d.createElement(s);" +
				"js.id = id;" +
				"js.src = '//connect.facebook.net/de_DE/sdk.js#xfbml=1&version=v2.5\';" +
				"fjs.parentNode.insertBefore(js, fjs);" +
				"}(document, 'script', 'facebook-jssdk'));</script>" +
				"<div class=\"fb-page\" data-href=\"https://www.facebook.com/unsereOEBB\" data-tabs=\"timeline\" data-height=\"256\" data-width=\"450\" data-small-header=\"true\" data-adapt-container-width=\"true\" data-hide-cover=\"true\" data-show-facepile=\"false\">" +
				"<div class=\"fb-xfbml-parse-ignore\">" +
				"<blockquote cite=\"https://www.facebook.com/unsereOEBB\">" +
				"<a href=\"https://www.facebook.com/unsereOEBB\"></a>" +
				"</blockquote>" +
				"</div>" +
				"</div>");

			var btn = $("<a href=\"#\" class=\"md-button mup-social-media-btn-disable\" title=\"Social Media deaktivieren\"><span class=\"ic-close2\"></a>");
			$(".mup-facebook").append(btn);

			btn.on('click', function (event) {
				event.preventDefault();
				disableSocialMedia()

			});

		}
		mupFacebookContent.show();
	}

	function activateTwitter() {
		var mupTwitterContent = $(".mup-twitter-content");

		if (mupTwitterContent.length == 0) {
			mupTwitterContent = $("<div class=\"mup-twitter-content\"></div>");
			$(".mup-twitter").prepend(mupTwitterContent);


			mupTwitterContent.html(
				"<a class=\"twitter-timeline\" href=\"https://twitter.com/unsereOEBB\" data-widget-id=\"704689839283834881\" data-chrome=\"nofooter\"></a>" +
				"<script>!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0],p=/^http:/.test(d.location)?'http':'https';if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src=p+'://platform.twitter.com/widgets.js';fjs.parentNode.insertBefore(js,fjs);}}(document,'script','twitter-wjs');</script>");

			var btn = $("<a href=\"#\" class=\"md-button mup-social-media-btn-disable\" title=\"Social Media deaktivieren\"><span class=\"ic-close2\"></a>");
			$(".mup-twitter").append(btn);

			btn.on('click', function (event) {
				event.preventDefault();
				disableSocialMedia()
			});
		}
		mupTwitterContent.show();
	}

	function disableSocialMedia() {
		$(".mup-twitter-content").hide();
		$(".mup-facebook-content").hide();
		$(".mup-social-media-btn-disable").hide();


		$(".mup-social-media").show()
		$.removeCookie('mup-social-media', {expires: 365, path: '/'});
	}

	function enableSocialMedia() {

		$.cookie('mup-social-media', 'ok', {expires: 365, path: '/'});
		activateFacebook()
		activateTwitter()

		$(".mup-social-media-btn-disable").show();
		$(".mup-social-media").hide()
	}

	$(".mup-social-media-btn").on('click', function (event) {
		event.preventDefault();
		enableSocialMedia();
	});
	return this.each(function () {
		var $mashup = $(this);

			$(".mup-social-media-btn-disable").hide();
					var cookieValue = $.cookie('mup-social-media');
			if (cookieValue && cookieValue == 'ok') {
				enableSocialMedia();
			}


	});

};
