/**
 * Created by z211567 on 26.05.2015.
 */

$.fn.navPlugin = function (options) {
	var pluginName = "navPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
		// color: "#556b2f",
		// backgroundColor: "white"
	}, options);
	// Do something to each element here.


	return this.each(function () {
		var $nav = $(this);
		$nav.attr("role","menu");
		if (!$nav.data(pluginName)) {
			var allListItems = new Array();
			var currentOpenListIem;
			var currentMenuPanel;


			$.each($nav.children(".site-nav__main-item"), function (index, listItem) {
				var $listItem = $(listItem);
				$listItem.attr("role","menuitem");
				allListItems.push($listItem);

				// Überprüfe ob ein Dropdown-Menüvorhanden ist.
				if ($listItem.children(".site-nav__menu-board").length > 0) {
					var menuItemId= idGen.getId();
					// Dropdown-Menü.
					var $handler = $listItem.children("a").first();
					var openMenu = function (event) {

						if (currentOpenListIem && currentOpenListIem != $listItem) {
							currentOpenListIem.removeClass("open");
							if (currentMenuPanel) {
								currentMenuPanel.hide();
								currentMenuPanel = null;
							}
						}
						$listItem.addClass("open");
						$handler.attr("aria-expanded","true");
						$(".nav-bar-close").show();

						var submenuPanel = $listItem.children(".site-nav__menu-board").first();
						if (submenuPanel.hasClass("full")) {
							submenuPanel.css("width", $(window).width());
							submenuPanel.css("left", $listItem.offset().left * -1);
						}

						submenuPanel.slideDown("fast", function () {
							$listItem.addClass("open");
							currentOpenListIem = $listItem;
							currentMenuPanel = submenuPanel;

						});


					};


					$handler.attr("aria-expanded","false");
					$handler.attr("aria-controls",menuItemId);
					var menuItem=$listItem.children("ul").first();
					menuItem.attr("id",menuItemId);

					menuItem.attr("role",'menu');
					menuItem.attr("tabindex",-1);
					$handler.hoverIntent(openMenu).on('focus', openMenu)
					var $lastlinkHandler = $listItem.find("a").last();
					$lastlinkHandler.on('blur',function (event) {
						closeMenu();
					});

				}
				else {
					var $handler = $listItem.children("a").first();
					$handler.hoverIntent(function (event) {
						$listItem.addClass("open");
						closeMenu();
					}, function (event) {
						$listItem.removeClass("open");
					})
				}
			})

			function closeMenu() {
				$( ".nav-bar a[aria-expanded='true']" ).attr("aria-expanded","false");

				$(".nav-bar-close").hide();
				if (currentOpenListIem) {
					currentOpenListIem.removeClass("open");
					currentOpenListIem = null;
				}
				if (currentMenuPanel) {
					currentMenuPanel.hide();
					currentMenuPanel = null;
				}
				$.each(allListItems, function (index, listItem) {
					listItem.removeClass("open");
					var menuPanel = listItem.children("ul").first();
					if (menuPanel) {
						menuPanel.hide();
					}
				});
			}


			window.addEventListener('scroll', function (event) {
				closeMenu()
			}, false);
			$("header > .container").on('mouseover', function (event) {
				closeMenu()
			})

			$(".nav-bar-close").on('mouseover', function (event) {
				closeMenu()
			})
			$(window).on('resize', function () {
				closeMenu()
			});


			$nav.data(pluginName, true);
		}


	});


};