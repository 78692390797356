(function ($) {
	$.fn.createTabsPlugin = function (options) {
		var pluginName = "createTabsPlugin";

		// default options.
		var settings = $.extend({

		}, options);

		return this.each(function () {
				var $tabContainerStartElements = $(".tabs-start");
				var $tabContainerEndElements = $(".tabs-end");
				var $tabStartElements = $(".tab-start");
				var bCascading = ($tabContainerStartElements.length == $tabContainerEndElements.length && $tabStartElements.length > 0);

				// get all the tab-container and find the tabs and their content
				// check for correct "cascading"
				if (bCascading) {
					$tabContainerStartElements.each(function(){
						var $ulElem = document.createElement("ul");
						var $ulStyleAttr =  document.createAttribute("class");
						$ulStyleAttr.value = "tabs";
						$ulElem.setAttributeNode($ulStyleAttr);

						// get the tab elements
						var $tabElements = $(this).nextUntil(".tabs-end").filter(".tab-start");
						$tabElements.each(function(){
							var $liElem = document.createElement("li");

							// title for the printing version
							var $titleForPrint = document.createElement("h2");
							var $titleForPrintClass = document.createAttribute("class");
							$titleForPrintClass.value = "hidden tab-title";
							$titleForPrint.setAttributeNode($titleForPrintClass);
							$titleForPrint.appendChild(document.createTextNode($(this).find("h2").text()));
							$liElem.appendChild($titleForPrint);

							var $hElem = document.createElement("span");
							$hElem.classList.add("tab-headline");
							$hElem.appendChild( document.createTextNode($(this).find("h2").text()) );
							$liElem.appendChild($hElem);

							// get the content of the tabs
							var $contentElements = $(this).nextUntil(".tab-start").filter(".md");
							$contentElements.each(function(){
								$(this).appendTo($liElem);
							});

							$ulElem.appendChild($liElem);
						});
						$(this).after($ulElem);
					});
					$tabContainerStartElements.remove();
					$tabContainerEndElements.remove();
					$tabStartElements.remove();
				}
			}
		);
	};
})(jQuery);