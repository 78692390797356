/**
 * Created by l025351 on 12.09.2016.
 */

$.fn.eventsHandlerPlugin = function (options) {
    var pluginName = 'eventsHandlerPlugin';

    // default options.
    var settings = $.extend(
        {
            // These are the defaults.
            selector: '',
            event: '',
            handler: function () {},
        },
        options
    );
    // Do something to each element here

    if (
        settings.selector != undefined &&
        settings.selector != '' &&
        settings.event != undefined &&
        settings.event != ''
    ) {
        // It is meant to be run for a specific object and a specific event
        $(settings.selector).on(settings.event, settings.handler);
    } else {
        // It is meant to bind all initial events

        // Click event on the Off-Canvas Menu link
        $('#off-canvas-link').on('click', function (event) {
            event.preventDefault();
            $('html').addClass('off-canvas-open');
        });

        // Click events on the skiplinks
        $('#first-skiplink').on('click', function (event) {
            event.preventDefault();
            $('#main').trigger('focus');
        });
        $('#second-skiplink').on('click', function (event) {
            event.preventDefault();
            $('#nav-bar').trigger('focus');
        });
        $('#third-skiplink').on('click', function (event) {
            event.preventDefault();
            $('#service-nav-search-icon').trigger('click');
        });

        // Click event on the print link on the footer
        $('#print-link, .js-btn-print').on('click', function () {
            window.print();
            return false;
        });

        // Click event on the mashups "More Results" button
        $(
            '#mashup-tiles:not(.js-mashup-tiles--werbung) .mup-more-button .button-text-box .button-more-next-mashups-page'
        ).on('click', function (event) {
            if (event.preventDefault) {
                event.preventDefault();
            } else {
                event.returnValue = false;
            }
            mashupUtils.moreMashups($(this).attr('data-component-url'));
        });

        // Click event on the mashups "Show All Results" button
        $(
            '#mashup-tiles:not(.js-mashup-tiles--werbung) .mup-more-button .button-text-box .button-more-all-mashups'
        ).on('click', function (event) {
            if (event.preventDefault) {
                event.preventDefault();
            } else {
                event.returnValue = false;
            }
            mashupUtils.showAllMashups();
        });

        // Click event on the mashups categories
        $(
            '#mashup-categories:not(.video-categories):not(.mup-category-list--werbung) ul li a'
        ).on('click', function (event) {
            stopEventPropagation(event);
            mashupUtils.mashupCategoryClick(
                this,
                $(this).attr('data-mashup-category'),
                $(this).parents('#mashup-categories').attr('data-url')
            );
            mashupUtils.callSocialTiles();
        });

        // Change event on the presseinformation mashups categories
        $('.press-mup-filter .container .row .col-1 form select').on(
            'change',
            function (event) {
                stopEventPropagation(event);
                mashupUtils.updatePresseMashups(
                    $(this).attr('data-component-url')
                );
            }
        );

        document.addEventListener('keydown', function (e) {
            var key = e.keyCode ? e.keyCode : e.which;
            if (e.keyCode !== 9) return;

            document.documentElement.classList.add('is-tabbing');
        });

        document.addEventListener('click', function (e) {
            document.documentElement.classList.remove('is-tabbing');
        });
    }
};
