$.fn.imgVisualCenterPlugin = function (options) {
	var pluginName = "imgVisualCenterPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
		// color: "#556b2f",
		// backgroundColor: "white"
	}, options);
	// Do something to each element here.

	return this.each(function () {
		var $imgVisualCenter = $(this);
		var $imageHolder = $imgVisualCenter.parents("li");
		var visualLeft = $imgVisualCenter.data("visual-center-left");
		if ($imgVisualCenter.data(pluginName) != pluginName) {
			function doPosition() {

				var currentLeft = visualLeft;
				var holderWidth = $imageHolder.width();
				currentLeft = (visualLeft - holderWidth / 2) * -1;
				if (currentLeft > 0) {
					currentLeft = 0;
				}

				if (($imgVisualCenter.width() - holderWidth - currentLeft * -1) < 0) {

					currentLeft = $imgVisualCenter.width() - holderWidth;
					currentLeft = currentLeft * -1;
				}

				$imgVisualCenter.css("left", currentLeft);
			}


			doPosition();
			if ($imgVisualCenter.data(pluginName) != pluginName) {

				$imgVisualCenter.data(pluginName, pluginName);


			}

			$(window).on('resize', function () {
				doPosition();

			});


		}
	});


};

