$.fn.lazyLoadImagesPlugin = function (options) {
	var pluginName = "lazyLoadImagesPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
	}, options);
	// Do something to each element here

    // Fallback for loading all images
    var fallback = function (image) {
        image.src = image.dataset.src;
        image.srcset = image.dataset.srcset;
    };

    // Loading if printing
    var that = this;
    var isPrinting = false;
    window.onbeforeprint = function () {
        isPrinting = true;
        return that.each(function () {
            fallback(this);
        });
    };
    if (isPrinting) return;

    // Lazy Loading
    if (
        'IntersectionObserver' in window &&
        'IntersectionObserverEntry' in window &&
        'intersectionRatio' in window.IntersectionObserverEntry.prototype &&
        'isIntersecting' in window.IntersectionObserverEntry.prototype
    ) {
        var intersectionObserver = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if(entry.isIntersecting) {
                    var image = entry.target;
                    intersectionObserver.unobserve(image);
                    image.src = image.dataset.src;
                    image.srcset = image.dataset.srcset;
                    image.classList.remove('js-lazy-image');
                }
            });
        }, {
            rootMargin: '300px'
        });

        return this.each(function () {
            intersectionObserver.observe(this);
        });
    } else {
        return this.each(function () {
            fallback(this);
        });
    }

};
