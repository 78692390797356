$.fn.siteimproveWidgetPlugin = function () {
    return this.each(function () {
        var siteimproveSiteId = this.getAttribute("data-siteimprove-site-id");
        var siteimproveGroupId = this.getAttribute("data-siteimprove-group-id");
        var siteimproveUrl = "https://api.siteimprove.com/v2/sites/" + siteimproveSiteId + "/dci/overview";
        if(!!siteimproveGroupId) {
            siteimproveUrl += "?group_id=" + siteimproveGroupId;
        }
        var $this = $(this);
        $.ajax({
            type: "GET",
            url: siteimproveUrl,
            dataType: 'json',
            headers: {
                "Authorization": "Basic ZXVnZW4uc2VlckBvZWJiLmF0OjY5NTM0NmViYmZkNGY5Njc4ODBjNGVlNzJjNDFkY2Zh"
            },
            success: function (data) {
                try {
                    var score = data.a11y.total;
                    $this.find(".dci-widget__score-bar__progress").css("width", score + '%');
                    $this.find(".dci-widget__score-bar__score").css("left", score + '%');
                    $this.find(".dci-widget__score-bar__score").html(score.toLocaleString("de-DE", {maximumSignificantDigits: 4}));
                } catch (e) {
                    console.log("JSON response does not contain accessibility.total: " + e);
                }
            }
        });
    });
};
