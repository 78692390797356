/**
 * Created by 171545 on 11.02.2019.
 */

$.fn.panoramaImagesPlugin = function (options) {
	var pluginName = "panoramaImagesPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
	}, options);
    // Do something to each element here



	return this.each(function (i, elm) {
        var panorama = elm.getAttribute('data-panorama-image-url'),
        panoramaId = $(elm).attr("id");
        pannellum.viewer(panoramaId, {
            'type': 'equirectangular',
            'panorama': panorama,
            'autoLoad': true,
            'compass': false
        });
    });
};
