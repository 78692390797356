/**
 * Created by l025351 on 12.09.2016.
 */

$.fn.webcamPlugin = function (options) {
	var pluginName = "webcamPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
	}, options);
	// Do something to each element here

	return this.each(function () {
		var webcamImage = $(this);

		// Reload timeout of 60 seconds for webcam images
		setInterval(function () {
			var src = webcamImage.attr('src');
			if (src.indexOf("?") !== -1) {
				src = src.substr(0, src.indexOf('?'));
			}
			src += '?t=' + new Date().getTime();
			webcamImage.attr('src', src);
		}, 60000);
	});

};
