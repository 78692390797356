$.fn.stoerungsmeldungenPlugin = function (options) {
    var pluginName = "stoerungsmeldungenPlugin";

    // default options.
    var settings = $.extend({
        // These are the defaults.
    }, options);
    // Do something to each element here

    var $element,
        data;

    return this.each(function () {
        $element = $(this);

        // data = [{
        //         "AbfZpt": "2020-03-06 10:49:16",
        //         "Service": "ARIS",
        //         "InfoOut": "Service steht",
        //         "StoerungsBeginn": true,
        //         "StoerungsEnde": false
        //     },
        //     {
        //         "AbfZpt": "2020-03-06 11:04:18",
        //         "Service": "ARIS",
        //         "InfoOut": "Service hat noch Probleme, wir sind bemüht",
        //         "StoerungsBeginn": false,
        //         "StoerungsEnde": false,
        //     },
        //     {
        //         "AbfZpt": "2020-03-06 11:09:19",
        //         "Service": "ARIS",
        //         "InfoOut": "Service Server Update wird installiert",
        //         "StoerungsBeginn": false,
        //         "StoerungsEnde": false,
        //     },
        //     {
        //         "AbfZpt": "2020-03-06 11:59:25",
        //         "Service": "ARIS",
        //         "InfoOut": "Service steht wieder zur Verfügung - Alle Probleme wurden gelöst",
        //         "StoerungsBeginn": false,
        //         "StoerungsEnde": true,
        //     },
        //     {
        //         "AbfZpt": "2020-03-06 11:59:25",
        //         "Service": "Service XY",
        //         "InfoOut": "Oje, Service reagiert nicht mehr",
        //         "StoerungsBeginn": true,
        //         "StoerungsEnde": false,
        //     },
        //     {
        //         "AbfZpt": "2020-03-07 12:59:25",
        //         "Service": "Service XY",
        //         "InfoOut": "Wir versuchen unser bestes",
        //         "StoerungsBeginn": false,
        //         "StoerungsEnde": false,
        //     },
        //     {
        //         "AbfZpt": "2022-03-06 11:04:18",
        //         "Service": "ARIS",
        //         "InfoOut": "Service hat schon wieder Probleme",
        //         "StoerungsBeginn": true,
        //         "StoerungsEnde": false,
        //     },
        //     {
        //         "AbfZpt": "2020-03-06 11:04:18",
        //         "Service": "Service ABC",
        //         "InfoOut": "Oh nein, schon wieder ein Service down.",
        //         "StoerungsBeginn": true,
        //         "StoerungsEnde": false,
        //     },
        //     {
        //         "AbfZpt": "2020-03-06 10:04:18",
        //         "Service": "Service Test",
        //         "InfoOut": "Reagiert nicht mehr",
        //         "StoerungsBeginn": true,
        //         "StoerungsEnde": false,
        //     },
        //     {
        //         "AbfZpt": "2020-03-06 11:04:18",
        //         "Service": "Service Test",
        //         "InfoOut": "Alles läuft wieder",
        //         "StoerungsBeginn": false,
        //         "StoerungsEnde": true,
        //     }
        // ]

        $.ajax({  
            url: "http://ws21576.org.oebb.at:8040/services/PfmStoerungAll/StoerungAll",
            type: 'GET',
            crossDomain: true,
            beforeSend: function (xhr) {
                //xhr.setRequestHeader ("Authorization", "Basic a2FyYWY6TmV2ZXJTZWVUaGVt");
                xhr.setRequestHeader("Accept", 'application/json');
            },
  
            success: function(receivedData) {

                if(receivedData.TalendResult && receivedData.TalendResult.rows && receivedData.TalendResult.rows.length > 0) {
                    data = receivedData.TalendResult.rows;
                } else {
                    data = [];
                }

                var groupedData = data.reduce(function (result, current) {
                    if(current.Service.length > 0) {
                        result[current.Service] = result[current.Service] || [];
                        result[current.Service].push(current);
                        
                        result[current.Service].sort(function(a, b) {
                            return new Date(b.AbfZpt) - new Date(a.AbfZpt);
                        });
                    }
                    
                    return result;
                }, {});

                for(var propName in groupedData) {
                    createMessageTimeline(propName, groupedData[propName]);
                }

                if($.isEmptyObject(groupedData)) {
                    $element.append("Keine Störungsmeldungen vorhanden.");
                }
                              
                initEventListener();
                
            }, 
            error: function(e) {
                console.log("Error: Data Could not be loaded.")
            }
        });
        
        // $.ajax("http://api-gateway-dev.oebb.at/gateway/PLF_Stoerungen/1.0/StoerungAll", {  
        //     type: 'GET',
        //     crossDomain: true,
        //     beforeSend: function(xhr) {
        //         xhr.setRequestHeader("x-centrasite-apikey", 'd14a0085-813c-40c0-bdf1-83730f19d09a');
        //         xhr.setRequestHeader("accept", 'application/json');
        //     }, 
        //     success: function() {
        //         console.log("oh ja")
        //     }, 
        //     error: function(e) {
        //         console.log(e)
        //         console.log("oh neiiinxxxx")
        //     }
        //   });

        
    });

    function createMessageTimeline(service, timeline) {
        var messageStatus = "warning";
        
        var $messageContainer = $('<div class="stoerungsmeldung" />');
        var $messageIcon = $('<span class="ic-warning stoerungsmeldung__icon" />');
        $messageContainer.append($messageIcon);

        var $messageLinkTitle = $('<a href="#" class="stoerungsmeldung__link-title" />');
        var $messageArrow = $('<span class="stoerungsmeldung_arrow ic-arrow1-down" />');
        $messageLinkTitle.append($messageArrow);

        var $messageTitle = $('<h2 class="stoerungsmeldung__title" />');
        $messageTitle.html(service);
        $messageLinkTitle.append($messageTitle);

        $messageContainer.append($messageLinkTitle);

        var $messageDetail = $('<ul class="stoerungsmeldung__timeline" />');
        $messageContainer.append($messageDetail);

        for (var i = 0; i < timeline.length; i++) {
            var timelineItemData = timeline[i];

            var $timelineItem = $('<li class="stoerungsmeldung__timeline-item" />');
            
            var $timelineTitle = $('<h3 class="stoerungsmeldung__item-title" />');
            $timelineTitle.html(timelineItemData.AbfZpt);

            var $timelineMessage = $('<p />');
            $timelineMessage.html(timelineItemData.InfoOut)

            if (timelineItemData.StoerungBeginn) {
                $timelineItem.addClass('stoerungsmeldung__timeline-item--error');

                if (i == 0) messageStatus = "error";
            } else if (timelineItemData.StoerungEnde) {
                $timelineItem.addClass('stoerungsmeldung__timeline-item--success');
                if (i == 0) messageStatus = "success";
            }

            $timelineItem.append($timelineTitle);
            $timelineItem.append($timelineMessage);
            $messageDetail.append($timelineItem);
        }

        $messageContainer.addClass('stoerungsmeldung--' + messageStatus);
        $element.append($messageContainer);
    }

    function initEventListener() {
        $('.stoerungsmeldung__link-title').on('click', function (e) {
            e.preventDefault();

            $(this).closest('.stoerungsmeldung').toggleClass('stoerungsmeldung--opened');
            $(this).closest('.stoerungsmeldung').find('.stoerungsmeldung__timeline').slideToggle();
        });
    }
};