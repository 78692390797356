$.fn.videoDisclaimerPlugin = function (options) {

	var pluginName = 'videoDisclaimerPlugin';

    var settings = $.extend({}, options);
    
  
	return this.each(function () {
        var lang = document.querySelector("html").getAttribute("lang");
        var videoFrames = document.querySelectorAll('.video-container-outer .js-yt-video');

        if (videoFrames && videoFrames.length > 0) {
            prepareDisclaimerWrapper();
            prepairDisclaimerBtn();
            main(null);
        }

        function main(event) {
            var videoFrames = document.querySelectorAll('.video-container-outer .js-yt-video');
            var videosAreAllowed = videosAreEnabled();
            if (videosAreAllowed) {

                for (var i = 0; i < videoFrames.length; i ++) {
                    var videoFrame = videoFrames[i];
                    var videoWrapper = videoFrame.closest('.video-container-outer');

                    videoWrapper.classList.add('video--activated');
                    videoFrame.classList.remove('video-deactivated');
                    videoFrame.style.pointerEvents = 'unset';
                    

                    if (!videoFrame.classList.contains('js-lazy-frame')) {
                        videoFrame.src = videoFrame.getAttribute('data-framesrc');
                    }

                    videoWrapper.classList.add('video--ready');
                    setDisclaimerTxt(videoWrapper, videosAreAllowed);
                }

                if (event !== undefined && event !== null) {
                    const videoIFrame = $(event.target).parent().children().first().children().first()[0];
                    videoIFrame.scrollIntoView();
                    videoIFrame.focus();
                }
            } else {

                for (var i = 0; i < videoFrames.length; i ++) {
                    var videoFrame = videoFrames[i];
                    var videoWrapper = videoFrame.closest('.video-container-outer');

                    videoWrapper.classList.remove('video--activated');
                    videoFrame.classList.add('video-deactivated');
                    videoFrame.style.pointerEvents = 'none';
                    videoFrame.src = "";
                    videoWrapper.classList.add('video--ready');
                    setDisclaimerTxt(videoWrapper, videosAreAllowed);
                }                    
            }
        }

        function prepareDisclaimerWrapper() {
            for (var i = 0; i < videoFrames.length; i ++) {
                var videoFrame = videoFrames[i];
                var videoWrapper = videoFrame.closest('.video-container-outer');

                if (!videoWrapper.querySelector('.video__disclaimer')) {
                    var disclaimer = document.createElement("p");
                    disclaimer.classList.add('video__disclaimer');
                    disclaimer.innerHTML = getPropertyText('disclaimerText');
                    videoWrapper.appendChild(disclaimer);
                }
            }
        }

        function prepairDisclaimerBtn() {
            for (var i = 0; i < videoFrames.length; i ++) {
                var videoFrame = videoFrames[i];
                var videoWrapper = videoFrame.closest('.video-container-outer');

                if (!videoWrapper.querySelector('.js-disclaimer-btn')) {
                    var disclaimerBtn = document.createElement("button");
                    disclaimerBtn.classList.add('js-disclaimer-btn');
                    disclaimerBtn.classList.add('md-button');
                    disclaimerBtn.classList.add('video-disclaimer-btn');
                    disclaimerBtn.setAttribute('aria-label', getPropertyText('disclaimerBtnAriaLabel'))
                    
                    videoWrapper.appendChild(disclaimerBtn);
                    
                    disclaimerBtn.addEventListener("click",  function (event) {
                        setLocalStorage();
                        main(event);
                        $(".waterfall-template:not(.edit-mode)").waterfallVideosDisclaimerPlugin();
                    });
                }
            } 
        }

        function setDisclaimerTxt(givenVideoWrapper, isEnabled) {
            var disclaimerBtn = givenVideoWrapper.querySelector('.js-disclaimer-btn');

            if (isEnabled) {
                disclaimerBtn.textContent = getPropertyText('rejectButton');
                disclaimerBtn.setAttribute('aria-pressed', true);
            } else {
                disclaimerBtn.innerHTML = '<span class="ic-play1"></span> ' + getPropertyText('acceptButton');
                disclaimerBtn.setAttribute('aria-pressed', false);
            }        
        }

        function getPropertyText(property) {
            var texts = $.fn.i18nPlugin();

            return texts[property] ? (texts[property][lang] ? texts[property][lang] : texts[property]["en"] ) : "";
        }

        function videosAreEnabled() {
            return localStorage.getItem('oebb-yt-videos') ? true : false;
        }

        function setLocalStorage() {
            if (videosAreEnabled()) {
                localStorage.removeItem('oebb-yt-videos');
            }  else {
                localStorage.setItem('oebb-yt-videos', "true");
            }
        }

    });

};

