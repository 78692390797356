/**
 * Created by 171545 on 11.02.2019.
 */

$.fn.previewSliderPlugin = function (options) {
	var pluginName = "previewSliderPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
	}, options);
    // Do something to each element here


    // UTIL FUNCTIONS
    var isMobileViewport = function isMobileViewport() {
        return (window.innerWidth < 768);
    }

    var isTabletViewport = function isTabletViewport() {
        return ((window.innerWidth < 920 && window.innerWidth >= 768));
    }

    var isDesktopViewport = function isDesktopViewport() {
        return (window.innerWidth >= 920);
    }

    var getVisibleItems = function getVisibleItems() {
        if(isDesktopViewport()) {
            return 5;
        } else if (isTabletViewport()) {
            return 3;
        } else {
            return 2;
        }
    }

    // END UTIL FUNCTIONS



	return this.each(function (i, elm) {
        var $elm = $(elm);
        var $itemsWrapper = $elm.find('.preview-slider__list');
        var $previewSlider = $elm.find('.preview-slider__view');
        var $btnForward = $elm.find('.preview-slider__control--forward');
        var $btnBackward = $elm.find('.preview-slider__control--backward');
        var $items = $elm.find('.preview-slider__item');
        var $itemsLink = $items.find('.preview-slider__link');
        var $activeLink = $elm.find(".preview-slider__item--active .preview-slider__link")

        var visibleItems = getVisibleItems();
        var itemsCount = $items.length;
        var currentIndex = 0;
        var currentPosition = 0;


        var updateDom = function updateDom() {
            if(currentIndex == 0 && !$btnBackward.hasClass('preview-slider__control--inactive')) {
                $btnBackward.addClass('preview-slider__control--inactive');
            } else {
                $btnBackward.removeClass('preview-slider__control--inactive');
            }

            if(currentIndex >= itemsCount - visibleItems && !$btnForward.hasClass('preview-slider__control--inactive')) {
                $btnForward.addClass('preview-slider__control--inactive');
            } else {
                $btnForward.removeClass('preview-slider__control--inactive');
            }

            $items.css('width', 100/visibleItems + '%');

            updateTabIndex();
        }

        var triggerTransition = function triggerTransition() {
            $itemsWrapper.css('transform', 'translateX('+ currentPosition +'%)');

            updateDom();
        }

        var updateTabIndex = function updateTabIndex() {
            $items.each(function(i, elem){
                if(i < currentIndex + visibleItems && i >= currentIndex) {
                    $(elem).find('.preview-slider__link').removeAttr('tabindex');
                } else {
                    $(elem).find('.preview-slider__link').attr('tabindex','-1');
                }
            });
        }

        var loadPanorama = function loadPanorama($link) {
            var source = $link.data("source");
            var panoramaLink = $link.data("panoramaUrl");
            var panoramaId = $link.data("panoramaId");

            $listItem = $link.parent();
            $previewSlider.html("");
            $previewSlider.attr("data-source", source == "image" ? "image" : "frame");

            if(source == "image") {
                var $div = $("<div id='panorama-" + panoramaId +"' class='panorama js-panorama-image' />");
                $div.attr("data-panorama-image-url", panoramaLink);
                $previewSlider.append($div)

                initPanoramaImages();

            } else {
                var $iframe = $("<iframe />");
                $iframe.attr("src", panoramaLink);
                $previewSlider.append($iframe);
            }

            $activeLink.removeClass('preview-slider__item--active');
            $listItem.addClass('preview-slider__item--active');
            $activeLink = $listItem;
        }

        updateDom();

        // Event-Listeners
        $btnForward.on('click', function(event){
            event.preventDefault();
            currentIndex++;
            currentPosition -= 100 / visibleItems;

            triggerTransition();
        });

        $btnBackward.on('click', function(event){
            event.preventDefault();
            currentIndex--;
            currentPosition += 100 / visibleItems;

            triggerTransition();
        });

        $(window).on('resize', function(){
            currentPosition = 0;
            currentIndex = 0;
            visibleItems = getVisibleItems();
            triggerTransition();
        });

        $itemsLink.on('click', function(event){
            event.preventDefault();
            loadPanorama($(this));
        });

        $(document).on('ready', function(){
            loadPanorama($activeLink);
        });

	});

};
