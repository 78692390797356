$.fn.scrollClasses = function (options) {
    var pluginName = "scrollClasses";

    // default options.
    var settings = $.extend({
        // These are the defaults.
    }, options);
    // Do something to each element here

    var elem;

    return this.each(function () {
        elem = this;

        var body = document.body;
        var scrollUp = "scroll-up";
        var scrollDown = "scroll-down";
        var lastScroll = 0;
 
        window.addEventListener("scroll", function() {
            var currentScroll = window.pageYOffset;
            if (currentScroll <= 0) {
                body.classList.remove(scrollUp);
                return;
            }
            
            if (currentScroll > lastScroll && !body.classList.contains(scrollDown) && elem.getBoundingClientRect().top < currentScroll - window.innerHeight) {
                // down
                body.classList.remove(scrollUp);
                body.classList.add(scrollDown);
                
            } else if (currentScroll < lastScroll && body.classList.contains(scrollDown) && elem.getBoundingClientRect().top < currentScroll - window.innerHeight) {
                // up
                body.classList.remove(scrollDown);
                body.classList.add(scrollUp);
            }
            lastScroll = currentScroll;
        });
    });

};