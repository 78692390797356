/**
 * Created by l025351 on 12.09.2016.
 */

$.fn.dmsTablePlugin = function (options) {
	var pluginName = "dmsTablePlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
	}, options);

	// Do something to each element here
	return this.each(function () {
		var dmsTable = $(this);
		$(".md-dms .row .col-2 button").on('click', function () {
			$(this).find(".ic-zoomin, .ic-zoomout").toggleClass("ic-zoomin ic-zoomout");
			$(".main-content > .container > .row > div.col-1:first-child").toggleClass("dms-fullscreen-col-1");
			$(".main-content > .container > .row > div.col-3").toggleClass("dms-fullscreen-col-3");
		});
		$(".md-dms input[type='text']").on("keyup input", function () {
			var tableRows = dmsTable.find("tbody tr");
			if (this.value == "") {
				tableRows.show();
				return;
			}

			var data = this.value.toUpperCase();
			tableRows.hide();

			tableRows.filter(function (i, v) {
				var $t = $(this);
				var regex = new RegExp(data.replace(/\*/g, ".*"), "g");
				return regex.test($t.text().toUpperCase());
			}).show();//show the rows that match.
		});
		$(".md-dms #select-unselect-all").on("click", function(){
			$('.md-dms table td input[type="checkbox"]').prop('checked', $(this).prop('checked'));
		});

		var $downloadForms = dmsTable.parents('.js-dms-download-form');
		if ($downloadForms) {
			$downloadForms.click(function (e) {
				if ($(e.target).hasClass('js-dms-download-form-checkbox') || $(e.target).hasClass('js-dms-download-form-checkbox-all')) {
					$(this).find('.js-dms-download-form-validation-message').slideUp();
				}
			});

			$downloadForms.submit(function (e) {
				var checkedBoxes = $(this).find('.js-dms-download-form-checkbox:checked');
				if (checkedBoxes.length > 0) return;

				e.preventDefault();

				var $validationMessage = $(this).find('.js-dms-download-form-validation-message').first();
				if ($validationMessage.length === 0) {
					$validationMessage = $('<p class="md-dms-download-form-validation-message js-dms-download-form-validation-message" aria-live="polite"></p>');
					$(this).find('[type="submit"]').before($validationMessage);
					$validationMessage.hide();
					$validationMessage.slideDown();
				} else {
					$validationMessage.slideDown();
				}

				// Timeout to let the screen reader read it
				setTimeout(function () {
					$validationMessage.text($downloadForms.data('validation-message'));
				});
			});
		}
	});

};
