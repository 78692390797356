/**
 * Created by z211567 on 26.05.2015.
 */

$.fn.iframeOverlayPlugin = function (options) {
	var pluginName = "iframeOverlayPlugin";

	// default options.
	var settings = $.extend({

	}, options);
	// Do something to each element here.


	return this.each(function () {
		var $iframeOverlayLink = $(this);
		if (!$iframeOverlayLink.data(pluginName)) {

			// *  *  *


			var openerLink=$iframeOverlayLink.get(0)
			var overlay
			var iframe
			var overlayTitle

			var closeLink = document.querySelector("a")
			if (!document.getElementById("iframe-overlay")) {

				 overlay = document.createElement("div")
				document.body.appendChild(overlay)
				overlay.classList.add("iframe-overlay")
				overlay.id = "iframe-overlay"


				var iframeHolder = document.createElement("div")
				overlay.appendChild(iframeHolder)
				iframeHolder.classList.add("iframe-holder")

				overlayTitle = document.createElement("h3")

				iframeHolder.appendChild(overlayTitle)

				closeLink = document.createElement("a")
				closeLink.href = "#"
				iframeHolder.appendChild(closeLink)

				iframe = document.createElement("iframe")
				iframe.setAttribute("frameborder", "0")
				iframe.setAttribute("allowfullscreen", "true")
				iframe.setAttribute("allowtransparency", "true")
				iframeHolder.appendChild(iframe)
				closeLink.innerHTML = "&times;"

			} else {
				overlay = document.querySelector(".iframe-overlay")
				iframe = overlay.querySelector("iframe")
				overlayTitle = overlay.querySelector("h3")

				closeLink = document.querySelector("a")
			}

			
			var overlayClass = $iframeOverlayLink.attr("data-overlay-class");

			if(overlayClass) {
				overlay.classList.add(overlayClass);
			}
			
			openerLink.addEventListener("click", function (event) {
				event.preventDefault();
				event.stopPropagation();
				overlay.classList.remove(openerLink.getAttribute("data-overlay-class"))
				iframe.src = openerLink.getAttribute("data-overlay-url")
				overlayTitle.innerText = openerLink.getAttribute("data-overlay-title")
				overlay.classList.add(openerLink.getAttribute("data-overlay-class"))
				overlay.style.display = "block";
			});


			closeLink.addEventListener("click", function (event) {
				event.preventDefault();
				event.stopPropagation();
				overlay.style.display = "none";
				iframe.src = ""
				overlayTitle.innerText = ""

			});

			// * * * *
			$iframeOverlayLink.data(pluginName, true);
		}


	});


};