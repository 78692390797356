/**
 * Created by z211567 on 26.05.2015.
 */

$.fn.accordeonPlugin = function (options) {
	var pluginName = "accordeonPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
		// color: "#556b2f",
		// backgroundColor: "white"
	}, options);
	// Do something to each element here.

	var $toggleButton;

	return this.each(function () {
		var $accordeon = $(this);

		if (!$accordeon.data(pluginName)) {
			var hasMoreThanOneItem = $accordeon.children("li").length > 1;

			if (hasMoreThanOneItem) {
				addToggleButton($accordeon);
			}

			$.each($accordeon.children("li"), function (index, listItem) {
				var $listItem = $(listItem);

				var $handler = $("<a aria-expanded=\"false\"></a>");
				var itemId= idGen.getId();
				$listItem.prepend($handler);
				$handler.append($("<span class=\"ic-plus3\" aria-hidden=\"true\"></span>"));
				$handler.attr("aria-controls",itemId);
				$handler.attr("href","#" + itemId);

				$handler.append($listItem.find(":header").first());


				var listItemContent = $listItem.children("div").first();
				listItemContent.attr("id",itemId);

				listItemContent.attr("role",'region');
				listItemContent.attr("aria-label", $listItem.find(":header").text());
				listItemContent.attr("tabindex",-1);

				$handler.on('click', function (event) {
					event.preventDefault();
					if ($listItem.hasClass("open")) {
						$handler.attr("aria-expanded",'false');
						$listItem.removeClass("open");
					} else {
						$listItem.addClass("open");
						$handler.attr("aria-expanded",'true');
					}

					if (hasMoreThanOneItem) {
						updateToggleButton($accordeon);
					}

				});

			})


			$accordeon.data(pluginName, true);
		}


	});

	function addToggleButton($accordeon) {
		$toggleButton = $('<button />');
		$toggleButton.addClass('accordeon__toggle-btn');
		$toggleButton.attr('data-status', 'openAll');

		$toggleButton.html($accordeon.attr('data-open-all'));
		$toggleButton.insertBefore($accordeon);

		$toggleButton.on('click', function(event) {
			event.preventDefault();

			if($(this).attr('data-status') == 'closeAll') {
				$(this).attr('data-status','openAll');
				$(this).html($accordeon.attr('data-open-all'));
				$accordeon.children("li").removeClass('open');
				$accordeon.children("li").children('a').attr("aria-expanded",'false');
			} else {
				$(this).attr('data-status','closeAll');
				$(this).html($accordeon.attr('data-close-all'));
				$accordeon.children("li").addClass('open');
				$accordeon.children("li").children('a').attr("aria-expanded",'true');
			}
		});

	}

	function updateToggleButton($accordeon) {
		var allItems = $accordeon.children("li");
		var openItems = $accordeon.children("li.open");

		if(openItems.length < allItems.length) {
			$toggleButton.attr('data-status','openAll');
			$toggleButton.html($accordeon.attr('data-open-all'));
		} else {
			$toggleButton.attr('data-status','closeAll');
			$toggleButton.html($accordeon.attr('data-close-all'));
		}
	}


};