$.fn.fessBahnhofResultsPlugin = function (options) {
    var pluginName = "fessBahnhofResultsPlugin",
        appBaseUrl = $("#spsearchtext-fess").attr("data-search-url");



	// default options.
	var settings = $.extend({
		// These are the defaults.
		serviceBaseUrl: appBaseUrl + "/fess-search",
		lang: "de"
    }, options);

    function getSiteLabel(searchlabel) {
		switch(searchlabel){
            case "corpbahnhofsearch":
                return "ÖBB-Bahnhof";
            case "corpinfrasearch":
                return "ÖBB-Infra";
            case "corpbildungsprogrammsearch":
                return "ÖBB-Infra Bildungsprogramm";
            default:
                return "";
        }
    }


	return this.each(function () {
		var maxResults = 100;
        var noResultsWord = $(this).attr("data-no-results-word");
        var searchCluster = $("#spsearchtext-fess").attr("data-search-cluster");
		var searchKeyword = $("#spsearchtext-fess").attr("data-search-keyword");

		$.getJSON(settings.serviceBaseUrl+"-"+searchCluster+"?q="+encodeURIComponent(searchKeyword)+"&lang="+settings.lang+
			"&maxResults="+maxResults, function(data) {

            var uniqueFilter = function (value, index, that) {
                return that.indexOf(value) === index;
            };

            var countElement = function(item,array) {
                var count = 0;
                $.each(array, function(i,v) { if (v === item) count++; });
                return count;
            }

            var labels = $.map(data.hits, function(elem){
                return elem.label;
            });

            var labelsFiltered = labels.filter(uniqueFilter).sort();

            for(var i = 0; i < labelsFiltered.length; i++) {
                var hitsCount = countElement(labelsFiltered[i],labels);
                var htmlToAppend = '<li role="region">';
                    htmlToAppend += '<h3 class="tab-headline">'+ getSiteLabel(labelsFiltered[i]) +' ('+ hitsCount + ')</h3>';
                    htmlToAppend += '<div><ul class="search-result-list">';

                for(var z = 0; z < data.hits.length; z++) {
                    var result = data.hits[z];

                    if(result.label == labelsFiltered[i]) {
                        htmlToAppend += "<li class=\"search-result-item\">";
                        htmlToAppend += "<div class=\"search-result-item-title\">";
                        htmlToAppend += "<a title=\"" + result.title + "\" href=\"" + result.url + "\">";
                        htmlToAppend += "<span class=\"ic-arrow2-right\" aria-hidden=\"true\"></span>"+result.title + "</a></div>";
                        htmlToAppend += "<div class=\"search-result-item-excerpt\">"+result.content+"</div>";
                        htmlToAppend += "<div class=\"search-result-item-url\">"+result.url+"</div></li>";
                    }
                }

                htmlToAppend += "</div></ul></li>";
                $('#fess-bahnhof-search-results .search-tabs').append(htmlToAppend);
            }

            if(labelsFiltered.length == 0) {
                $('#fess-bahnhof-search-results .search-tabs').append("<div class=\"text-block\"><p>" + noResultsWord + " " + searchKeyword + ".</p></div>");
            }

            $('#fess-bahnhof-search-results .search-tabs').attr('aria-busy', 'false');
            $("#fess-bahnhof-search-results .search-tabs").tabsPlugin();
        });

	});
};

