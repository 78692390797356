
$.fn.readmorePlugin = function (options) {
	var pluginName = "readmorePlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
		// color: "#556b2f",
		// backgroundColor: "white"
	}, options);
	// Do something to each element here.


	return this.each(function () {
        var $elem = $(this),
            $readmoreOuter = $elem.find('.readmore__outer'),
            $readmoreToggler = $elem.find('.readmore__toggler');

            $readmoreOuter.css('display', 'none');



        $readmoreToggler.on('click', function (event) {
            event.preventDefault();
            $elem.toggleClass('readmore--open');
            $readmoreOuter.slideToggle();
        })


	});


};