
$.fn.fadeInTeaserPlugin = function (options) {
	var pluginName = "fadeInTeaserPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
		// color: "#556b2f",
		// backgroundColor: "white"
	}, options);
	// Do something to each element here.


    function fadeInTeaser($elem) {
        if(hasScrolledIntoView($elem)) {
            $elem.addClass("faded");
        }
    }

    function hasScrolledIntoView($elem) {
        var docViewTop = $(window).scrollTop(),
            docViewBottom = docViewTop + $(window).height(),
            elemTop = $elem.offset().top;

        return elemTop < docViewBottom;
    }



	return this.each(function () {
        var $this = $(this);
        fadeInTeaser($this);

        $(window).on("scroll",function() {
            requestAnimationFrame(function() {
                fadeInTeaser($this);
            });
        });
	});


};