/**
 * Created by z211567 on 26.05.2015.
 */

$.fn.xparallaxPlugin = function (options) {
	var pluginName = "xparallaxPlugin";
	// default options.
	var settings = $.extend({
		// These are the defaults.
	//	color: "#556b2f",
	//	backgroundColor: "white"
	}, options);
	// Do something to each element here.
	return this.each(function () {
		var $parallax = $(this)
		if($parallax.data(pluginName)!=pluginName){
			var window_width = $(window).width();
			var $this = $(this);
			$this.addClass('parallax');
			 var scrollStart=-1;
			//var display= $this.parent().find('.display').first();
			//display.html("Hallo");
			function updateParallax(initial) {
				window_width = $(window).width();
				var $img = $this.children("img").first();

				if(window_width<768){
					$img.width(768);
				} else{
					$img.css("width","100%");
				}

				$this.parent().height($img.height()-100);
				var img_height = $img.height();
				var container_height;
				if (window_width < 601) {
					container_height = ($this.height() > 0) ? $this.height() : $this.children("img").height();
				}
				else {
					container_height = ($this.height() > 0) ? $this.height() : $this.children("img").height();
				}




				var parallax_dist = img_height - container_height;

				var bottom = $this.offset().top + container_height;
				var top = $this.offset().top;
				var scrollTop = $(window).scrollTop();

				var windowHeight = window.innerHeight;

				var windowBottom = scrollTop + windowHeight-80;
				var percentScrolled = (windowBottom - top) / (container_height + windowHeight);     // orig
			//	var percentScrolled = 100 / top* (top-scrollTop);     // orig

				var parallax = Math.round(((parallax_dist) * percentScrolled));

				if (initial) {
					$img.css('display', 'block');
				}
				var scrolled=0;

				if ((bottom> scrollTop) && (top < (scrollTop + windowHeight))) {
					//console.log("percentScrolled: "+percentScrolled +" "+parallax);
					//$img.css('transform', "translate3D(-50%," + parallax + "px, 0)");



					 if(scrollTop >0  && scrollStart==-1){
					 scrollStart=scrollTop;
					 }

					// console.log(scrollStart);
				//	 var par= 100/container_height*(scrollTop-scrollStart-container_height);
					 //console.log(par);

					scrolled =(parallax_dist) * 1/(windowHeight+container_height) * (scrollStart-scrollTop-80)*-1;
					$img.css('transform', "translate3D(-50%,"+scrolled + "px, 0)");
				}

			//	display.html("scrollTop: "+scrollTop+"; scrolled: "+scrolled+"; scrollStart: "+scrollStart+"; windowHeight: "+windowHeight+"; top: "+top+"; bottom: "+bottom+"; windowBottom: "+windowBottom);



			}

			// Wait for image load
			$this.children("img").one("load", function() {
				updateParallax(true);
			}).each(function() {
				if(this.complete) $(this).trigger('load');
			});

			$(window).on('scroll',function() {
				window_width = $(window).width();
				updateParallax(false);
			});

			$(window).on('resize', function() {
				window_width = $(window).width();
				updateParallax(false);
			});






		} else {
			//log(pluginName+" wurde schon initialisiert.")
		}

	});


};

