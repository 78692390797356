$.fn.tableDivisionPlugin = function (options) {

    var pluginName = 'tableDivisionPlugin';

    // default options.
    var settings = $.extend({
        // These are the defaults.
        // color: "#556b2f",
        // backgroundColor: "white"
    }, options);

    // Do something to each element here.
    return this.each(function () {
        $(this).removeClass('js-table-division');

        var $parent = $(this).parent();
        var $firstTable = $(this).clone();
        var $secondTable = $(this).clone();
        var divisionRowCount = Math.ceil($(this).find('tr').length / 2);

        // Remove original table
        $(this).remove();

        // Add columns
        $parent.append('<div class="row"><div class="col-2"></div><div class="col-2"></div></div>');

        // Remove last rows from first table
        $firstTable.find('tr').slice(divisionRowCount).remove();
        $firstTable.appendTo($parent.find('.col-2')[0]);

        // Remove first rows from second table
        $secondTable.find('tr').slice(0, divisionRowCount).remove();
        $secondTable.appendTo($parent.find('.col-2')[1]);
    });

};
