/**
 * Created by z211567 on 26.05.2015.
 */

$.fn.headerPlugin = function (options) {
	var pluginName = "headerPlugin";
	// default options.
	var settings = $.extend({
		// These are the defaults.
		color: "#556b2f",
		backgroundColor: "white"
	}, options);
	// Do something to each element here.
	return this.each(function () {

		var $header = $(this);
		var $html = $("html");
		if (!$header.data(pluginName)) {

			// log(pluginName+" wird initialisiert.")
			var docElem = document.documentElement,

				didScroll = false,
				changeHeaderOn = 20;

			function init() {
				// log(pluginName+" init");
				window.addEventListener('scroll', function (event) {
					if (!didScroll) {
						didScroll = true;
						setTimeout(scrollPage, 100);
					}
				}, false);
			}

			function scrollPage() {

				var sy = scrollY();
				if (sy >= changeHeaderOn) {
					if (!$html.hasClass("konzern-nav-open")) {
						$html.addClass("header-shrinked");
					}


				}
				else {

					$html.removeClass("header-shrinked");
				}
				didScroll = false;

				//$("#header-title").html("Hallo");
			}

			function scrollY() {

				return window.pageYOffset || docElem.scrollTop;
			}

			init();

			$header.data(pluginName, true);
		}

	});


};

