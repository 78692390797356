$.fn.tableFilterPlugin = function (options) {

    var pluginName = "tableFilterPlugin";

    var settings = $.extend({
        filterCheckboxSelector: 'input[type="checkbox"]:not(.js-tablefilter-personalization-switch)',
    }, options);

    var elem,
        formElem,
        filterToggler,
        filterResetBtn,
        filterTxtInput,
        resultTable,
        tableRows,
        saveButton,
        isPersonalization;

    var filters = [];
    var filterAmountNews;


    return this.each(function () {
        elem = this;
        filterAmountNews = elem.getAttribute("data-amount-news");
        filterInner = elem.querySelector(".tablefilter__inner");
        isPersonalization = elem.classList.contains("js-tablefilter--personalization");
        resultTable = elem.querySelector('.tablefilter-list');
        tableRows = resultTable.querySelectorAll("tbody tr");
        formElem = document.querySelector('.tablefilter-filter');
        filterToggler = document.querySelector('#tablefilter-filter-toggler');
        filterResetBtn = document.querySelector('#tablefilter-filter-reset');
        filterCheckboxes = document.querySelectorAll(settings.filterCheckboxSelector);
        filterTxtInput = document.querySelector('.tablefilter-filter__txt');
        filterDateFromInput = document.querySelector('.tablefilter-filter__date-from');
        filterDateUntilInput = document.querySelector('.tablefilter-filter__date-until');
        filterSublistToggler = document.querySelectorAll('.tablefilter-filter__sublist-toggler');
        saveButton = document.querySelector('#tablefilter-filter-save');

        if (isPersonalization) {
            loadFiltersFromLocalStorage();
            renderCheckedFilters();
            filterContentByFilters();
        }

        initEventListener();
    });

    function changeToggleText() {
        if (formElem.classList.contains('tablefilter-filter--open')) {
            filterToggler.innerHTML = '<span class="ic-arrow2-up" aria-hidden="true"></span> ' + filterToggler.getAttribute('data-filter-close');
        } else {
            filterToggler.innerHTML = '<span class="ic-arrow2-down" aria-hidden="true"></span> ' + filterToggler.getAttribute('data-filter-open');
        }
    }

    function initEventListener() {
        // Reset Button
        if (filterResetBtn) {
            filterResetBtn.addEventListener('click', function(e) {
                e.preventDefault();

                formElem.reset();
                showAllRows();
                disableResetButton();
                enableOrDisableSaveButton();
                fadeInAndOutResponse("reset");
            });
        }

        if (saveButton) {
            saveButton.addEventListener('click', function(e){
                e.preventDefault();
                
                localStorage.setItem('filters', JSON.stringify(filters));
                saveButton.classList.add("md-button--disabled");
                fadeInAndOutResponse("save")
                
            });
        }
        

        // Open/Close Filter
        if (filterToggler) {
            var documentWidth = window.innerWidth;

            if(documentWidth > 992) {
                formElem.classList.add("tablefilter-filter--open");
                changeToggleText();
            } else {
                changeToggleText();
            }


            filterToggler.addEventListener('click', function(e) {
                e.preventDefault();

                $(formElem).slideToggle();

                if (formElem.classList.contains('tablefilter-filter--open')) {
                    formElem.classList.remove('tablefilter-filter--open');
                } else {
                    formElem.classList.add('tablefilter-filter--open');

                    if(documentWidth <= 992) {
                        formElem.scrollIntoView();
                    }
                }

                changeToggleText();
            });
        }

        // Loop Event-Listener for all Checkboxes
        for(var i = 0; i < filterSublistToggler.length; i++) {
            var filterSublistTogglerBtn = filterSublistToggler[i];

            filterSublistTogglerBtn.addEventListener('click', function(e) {
                e.preventDefault();

                var btn = this;
                var parentofSelected = btn.parentNode;
                var children = parentofSelected.childNodes;
                var sublist = "";

                for (var i=0; i < children.length; i++) {
                    if(children[i].classList && children[i].classList.contains("tablefilter-filter__sublist")) {
                        sublist = children[i];
                        break;
                    }
                }

                $(sublist).slideToggle();
                $(btn).toggleClass('tablefilter-filter__sublist-toggler--open');

                if ($(btn).hasClass('tablefilter-filter__sublist-toggler--open')) {
                    btn.querySelector('span').classList.remove('ic-arrow2-down');
                    btn.querySelector('span').classList.add('ic-arrow2-up');
                } else {
                    btn.querySelector('span').classList.remove('ic-arrow2-up');
                    btn.querySelector('span').classList.add('ic-arrow2-down');
                }
            });
        }

        // Loop Event-Listener for all Checkboxes
        for(var i = 0; i < filterCheckboxes.length; i++) {
            var filterCheckbox = filterCheckboxes[i];

            filterCheckbox.addEventListener('change', function() {
                if(this.getAttribute("data-filter-type") == "sub-category" || this.getAttribute("data-filter-type") == "sub-organization") {
                    var mainBoxes =  $(this).parents(".tablefilter-filter__parent").find('input.tablefilter-filter__parent-box');

                    if (!mainBoxes.prop('checked')) {
                        mainBoxes.prop( "checked", true );
                    }
                }

                if(this.classList.contains('tablefilter-filter__parent-box')) {
                    var subBoxes =  $(this).parents(".tablefilter-filter__parent").find('input[type="checkbox"]');

                    if (!$(this).prop('checked')) {
                        subBoxes.prop( "checked", false );
                    }
                }

                if(this.classList.contains('tablefilter-filter__toggle-sublist')) {
                    var subBoxes =  $(this).parents(".tablefilter-filter__parent").find('input[type="checkbox"]');

                    if ($(this).prop('checked')) {
                        subBoxes.prop( "checked", true );
                    } else {
                        subBoxes.prop( "checked", false );
                    }
                }

                
                filterResult();
                enableOrDisableSaveButton();
            });
        }

        // Input-Field Filter
        if (filterTxtInput) {
            filterTxtInput.addEventListener('input', function() {
                filterResult();
            });
        }

        if (filterDateFromInput) {
            filterDateFromInput.addEventListener('input', function() {
                filterResult();
            });
        }

        if (filterDateUntilInput) {
            filterDateUntilInput.addEventListener('input', function() {
                filterResult();
            });
        }
    };

    function fadeInAndOutResponse(className) {
        var element = $('.response-message--' + className);
        element.addClass('response-message--fade');
        setTimeout(function() {
            element.removeClass('response-message--fade');

        },1000)
    }

    function enableOrDisableSaveButton() {
        var localFilter = getFiltersFromLocalStorage();
        var isSame = isArrayEqual(localFilter, filters);

        if (isSame) {
            saveButton.classList.add("md-button--disabled");
        } else {
            saveButton.classList.remove("md-button--disabled");
        }
    }

    function isArrayEqual(arr1, arr2) {
        return JSON.stringify(arr1) == JSON.stringify(arr2);
    }

    function filterResult() {
        if (filterInner) {
            filterInner.classList.add("loading");
        }
        
        var filterCategories = $(formElem).find('input[data-filter-type="category"]:checked').map(function(){
            return $(this).val();
          }).get();

        var filterStates = $(formElem).find('input[data-filter-type="states"]:checked').map(function(){
            return $(this).val();
          }).get();

        var filterOrganisation = $(formElem).find('input[data-filter-type="organisation"]:checked').map(function(){
            return $(this).val();
          }).get();

        var filterSubCategories = $(formElem).find('input[data-filter-type="sub-category"]:checked').map(function(){
            return $(this).val();
          }).get();

        var filterSubOrganisations = $(formElem).find('input[data-filter-type="sub-organisation"]:checked').map(function(){
            return $(this).val();
          }).get();

        var filterInput = filterTxtInput.value.toUpperCase();

        var filterDateFrom = filterDateFromInput ? filterDateFromInput.value : "";
        var filterDateUntil = filterDateUntilInput ? filterDateUntilInput.value : "";

        filters = [];

        if (filterCategories.length > 0) {
            filters.push({
                'filterType': 'category',
                'filterValue': filterCategories
            })
        }

        if (filterSubCategories.length > 0) {
            filters.push({
                'filterType': 'sub-category',
                'filterValue': filterSubCategories
            })
        }

        if (filterSubOrganisations.length > 0) {
            filters.push({
                'filterType': 'sub-organisation',
                'filterValue': filterSubOrganisations
            })
        }

        if (filterInput.length > 0) {
            filters.push({
                'filterType': 'input',
                'filterValue': filterInput
            })
        }

        if (filterStates.length > 0) {
            filters.push({
                'filterType': 'state',
                'filterValue': filterStates
            })
        }

        if (filterOrganisation.length > 0) {
            filters.push({
                'filterType': 'organisation',
                'filterValue': filterOrganisation
            })
        }

        if (filterDateFrom.length > 0) {
            filters.push({
                'filterType': 'dateFrom',
                'filterValue': filterDateFrom
            })
        }

        if (filterDateUntil.length > 0) {
            filters.push({
                'filterType': 'dateUntil',
                'filterValue': filterDateUntil
            })
        }
        

        filterContentByFilters();
    }

    function renderCheckedFilters() {
        if (!formElem) return;

        formElem.reset();

        if (filters.length == 0) return;

        for (var i = 0; i < filters.length; i++) {
            var filterType = filters[i].filterType;

            for (var j = 0; j < filters[i].filterValue.length; j++) {
                var filterValue = filters[i].filterValue[j];
                var selector = '[data-filter-type="' + filterType + '"][value="' + filterValue + '"]';
                var checkbox = document.querySelector(selector);

                $(checkbox).prop('checked', true);

                if ($(checkbox).parent().parent().hasClass('tablefilter-filter__sublist')) {
                    $(checkbox).parent().parent().show();

                    var $btn = $(checkbox).parent().parent().prev();

                    $btn.addClass('tablefilter-filter__sublist-toggler--open');
                    $btn.find('span').removeClass('ic-arrow2-down');
                    $btn.find('span').addClass('ic-arrow2-up');
                }
            }
        }
    }

    function filterContentByFilters() {
        var visibleRows = tableRows;
        var visibleRowsCount = 0;

        if (filters.length > 0) {
            for (var i = 0; i < filters.length; i ++) {
                var currentFilter = filters[i];
            
                for (var x = 0; x < visibleRows.length; x++) {
                    var tableRow = visibleRows[x];
                    tableRow.setAttribute('data-visible','false');

                    if (!isNaN(filterAmountNews) && visibleRowsCount == filterAmountNews) continue;

                    if (currentFilter.filterType == "input") {
                        if(tableRow.innerHTML.toUpperCase().indexOf(currentFilter.filterValue) > -1) {
                            tableRow.setAttribute('data-visible','true');
                            visibleRowsCount++;
                        }
                    } else {
                        for(var y = 0; y < currentFilter.filterValue.length; y++ ) {
                            var dataValue = currentFilter.filterValue[y],
                            rowDataValue = tableRow.getAttribute('data-' + currentFilter.filterType);

                            if (!isNaN(filterAmountNews) && visibleRowsCount == filterAmountNews) continue;

                            if (rowShouldBeVisible(rowDataValue, dataValue, currentFilter)) {
                                tableRow.setAttribute('data-visible','true');
                                visibleRowsCount++;
                                break;
                            }
                        }
                    }
                }
                visibleRows = resultTable.querySelectorAll('tbody tr[data-visible="true"]');
            }

            enableResetButton();

            if (visibleRowsCount == 0) {
                $('.js-tablefilter-message-empty').show();
            } else {
                $('.js-tablefilter-message-empty').hide();
            }

        } else {
            showAllRows();
            disableResetButton();
        }

        setTimeout(function(){
            if (filterInner) {
                filterInner.classList.remove("loading");
            }
        },500);
    }

    function rowShouldBeVisible(rowDataValue, dataValue, filter) {        
        if (filter.filterType == 'organisation') {
            rowDataValue = rowDataValue.replaceAll("ÖBB-", "");
            dataValue = dataValue.replaceAll("ÖBB-", "");
        }

        if (rowDataValue && (rowDataValue.indexOf(dataValue) > -1)) return true;
        if (filter.filterType == 'organisation' && (rowDataValue.indexOf('ÖBB') > -1)) return true;
        if (filter.filterType == 'state' && (rowDataValue.indexOf('Österreichweit') > -1)) return true;

        return false;
    }

    function enableResetButton() {
        if(filterResetBtn) {
            filterResetBtn.classList.remove("md-button--disabled");
        }
    }

    function disableResetButton() {
        if(filterResetBtn) {
            filterResetBtn.classList.add("md-button--disabled");
        }
    }

    function showAllRows() {

        filters = [];

        if (isPersonalization) {
            for (var i = 0; i < tableRows.length; i++) {
                tableRows[i].setAttribute('data-visible', 'false');
            }
            $('.js-tablefilter-message-empty').show();
        } else {
            for (var i = 0; i < tableRows.length; i++) {
                tableRows[i].setAttribute('data-visible','true');
            }
        }
    }



    function getFiltersFromLocalStorage() {
        var filtersLocalJsonString = localStorage.getItem('filters');
        var filtersLocal = filtersLocalJsonString ? JSON.parse(filtersLocalJsonString) : [];

        return filtersLocal;
    }

    function loadFiltersFromLocalStorage() {
        filters = getFiltersFromLocalStorage();
    }

};
