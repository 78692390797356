$.fn.accordeonSimplePlugin = function (options) {

    var pluginName = "accordeonSimplePlugin";

    var settings = $.extend({
        filterCheckboxSelector: 'input[type="checkbox"]:not(.js-tablefilter-personalization-switch)',
    }, options);


    return this.each(function () {
        $accordeonToggler = $(this);

        $accordeonToggler.on('click', function(e) {
            e.preventDefault();

            var accordeon = this.closest('.js-accordeon');
            $(accordeon).toggleClass('accordeon--open');

            var accordeonContent = accordeon.querySelector('.accordeon__content')
            $(accordeonContent).slideToggle();
        })

        closeAccordeon();

        $(window).on('resize', function() {
            closeAccordeon();
        });

        function closeAccordeon() {
            if (window.innerWidth <= 991) {
                $('.js-accordeon').removeClass('accordeon--open');
                $('.accordeon__content').css('display', 'none');
            }
        }
        
    });
};
