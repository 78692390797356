/**
 * Created by z211567 on 26.05.2015.
 */

$.fn.offcanvasPlugin = function (options) {
	var pluginName = "offcanvasPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
		locale: "de"
	}, options);
	// Do something to each element here.

	return this.each(function () {
		var showSubpagesButtonAriaLabel = "Unterseiten anzeigen";
		var hideSubpagesButtonAriaLabel = "Unterseiten ausblenden";

		if(settings.locale == "en"){
			showSubpagesButtonAriaLabel = "Show subpages";
			hideSubpagesButtonAriaLabel = "Hide subpages";
		}

		var lexicographicalOrder = false;
		if (this.dataset.lexicographicalOrder !== undefined && this.dataset.lexicographicalOrder !== null && this.dataset.lexicographicalOrder === "true") {
			lexicographicalOrder = true;
		}

		var lastActiveLevel = 0;
		var currentObject;
		var currentBtn;

		var createSubPanels = function ($navigation, $ocParentPanel, $ocBackBtn, parentLinkObj, level) {
			var channelId = $("#ocDataId").attr("data-value");

			if (channelId === parentLinkObj.uuid && parentLinkObj.isSubLevelHidden) {
				return;
			}

			var $ocSubPanel = $('<div class="oc-sub-panel" tabindex="-1" />');
			$ocSubPanel.attr("data-level", level);

			var $ocBackPanel = $('<div class="oc-back-panel"/>');
			$ocSubPanel.append($ocBackPanel);
			var $ocSubPanelBackBtn = $('<button class="oc-back-btn" aria-label="'+hideSubpagesButtonAriaLabel+'"><span aria-hidden="true" class="ic-arrow1-left"></span></button>');
			$ocSubPanelBackBtn.on('click', function (event) {
				event.preventDefault();
				//$ocParentPanel.css("visibility", "visible");
				$ocParentPanel.addClass('panel-visible');
				//$ocSubPanel.css("visibility", "visible");
				$ocSubPanel.addClass('panel-visible');
				$ocSubPanel.css("position", "absolute");
				$ocSubPanel.css("left", "250px");
				$ocParentPanel.css("left", 0);
				$ocParentPanel.css("top", 0);
				//$ocParentPanel.find("button").first().trigger('focus');

				setTimeout(function () {
					$ocParentPanel.trigger('focus');
					$ocParentPanel.css('position', 'relative');

					$('.oc-links').show();
					//$ocSubPanel.css("visibility", "hidden");
					$ocSubPanel.removeClass('panel-visible');
					$ocSubPanel.css("position", "absolute");

				}, 500);
			});


			$ocBackPanel.append($ocSubPanelBackBtn);

			var $ocSubPanelBackLink = $('<a href="" class="oc-back-link"></a>');
			$ocSubPanelBackLink.html('<span>' + parentLinkObj.text + '</span>');
			$ocSubPanelBackLink.attr('href', parentLinkObj.href);

			$ocBackPanel.append($ocSubPanelBackLink);

			var $navListLevel_3 = $('<ul />');
			$navListLevel_3.addClass('oc-nav');

			$ocSubPanel.append($navListLevel_3);
			//if(parentLinkObj.active){
			$navigation.append($ocSubPanel);
			//	}

			$.each(parentLinkObj.children, function (index3, linkObj3) {
				if (linkObj3.uuid == channelId ) {
					linkObj3.active = true;
					parentLinkObj.active = true;
				}

				if (linkObj3.isHidden) return; // Continue

				var $listItem3 = $('<li />');
				$navListLevel_3.append($listItem3);
				var $link3 = $('<a />');
				$listItem3.append($link3);
				if (linkObj3.active) $link3.addClass('oc-nav-active-link');

				if (linkObj3.isLocked) {
					$link3.append('<span>' + linkObj3.text + ' <span class="ic-lock" aria-hidden="true"></span></span>');
				} else {
					$link3.append('<span>' + linkObj3.text + '</span>');
				}

				$link3.attr('href', linkObj3.href);

				if (linkObj3.children && linkObj3.children.length > 0) {
					var $childBtn3 = $('<button aria-label="'+showSubpagesButtonAriaLabel+'"><span class="ic-arrow1-right" aria-hidden=\"true\"></span></button>');

					if (!linkObj3.isSubLevelHidden) {
						$listItem3.append($childBtn3);
					}

					/*if(linkObj3.active){
					$ocSubPanel.attr("data-active",true);
					}*/

					createSubPanels($navigation, $ocSubPanel, $childBtn3, linkObj3, level + 1);
				}
			});

			$ocSubPanel.css("left", "250px");

			if (parentLinkObj.active) {
				$ocSubPanel.attr("data-active", true);
				if (lastActiveLevel < level) {
					lastActiveLevel = level;
					currentObject = $ocSubPanel;
					currentBtn = $ocBackBtn;
				}
				$ocSubPanel.css("left", "-250px");
			} else {
				$ocSubPanel.css("left", "250px");
			}

			$ocBackBtn.on('click', function (event) {
				event.preventDefault();
				$('.oc-links').hide();
				//$ocParentPanel.css("visibility", "visible");
				$ocParentPanel.addClass('panel-visible');
				//$ocSubPanel.css("visibility", "visible");
				$ocSubPanel.addClass('panel-visible');
				$ocSubPanel.css("position", "absolute");
				$ocParentPanel.css("position", "absolute");

				$ocParentPanel.css("top", 0);
				$ocParentPanel.css("left", "-250px");

				//	$ocSubPanel.find("button").first().trigger('focus');

				//$ocSubPanel.css('visibility', 'visible');
				$ocSubPanel.addClass('panel-visible');
				$ocSubPanel.css("position", "absolute");
				$ocSubPanel.css("left", "0");
				$ocSubPanel.css("top", "0");

				setTimeout(function () {
					$ocSubPanel.trigger('focus');
					$ocSubPanel.css("position", "relative");
					$('.oc-links').show();
					//$ocParentPanel.css("visibility", "hidden");
					$ocParentPanel.removeClass('panel-visible');
					$ocParentPanel.css("position", "absolute");
				}, 500);


			})

		}
		var $offcanvas = $(this);

		if ($offcanvas.data(pluginName) != pluginName) {
			/* Offcanvas öffnen uns schließen */
			var offCanvasHandler = $('#off-canvas-handler');
			var offCanvasLink = $('#off-canvas-link');
			var logoLink = $('#logo-link');


			function showOffcanvas() {
				// OffCanvas öffnen
				$offcanvas.show();
				$('html').addClass('off-canvas-open');
				offCanvasLink.attr("aria-expanded", true);

				$offcanvas.addClass("offCanvasScroll");


				$(document).on("touchmove", function (event) {
					//$("#log").val($("#log").val()+"; "+$(event.target).parents(".offCanvasScroll").length);
					if ($(event.target).parents(".offCanvasScroll").length == 0) {
					event.preventDefault();
						event.stopPropagation();

					} else {
						event.preventBubble();
						event.stopPropagation();
						//event.preventDefault()
					}

				});


				var offcanvasCloseBtn = $offcanvas.find(".oc-close-btn");
				offcanvasCloseBtn.focus();

				offcanvasCloseBtn.unbind('keydown');
				offcanvasCloseBtn.on('keydown', function (e) {
					if (e.keyCode == 9 && e.shiftKey == true) {
						e.preventDefault();
						closeOffcanvas();
					}
				});

				offcanvasCloseBtn.unbind('click');
				offcanvasCloseBtn.on('click', function (event) {
					event.preventDefault();
					closeOffcanvas();
				});

				$(document).unbind("keyup");
				$(document).on('keyup', function (evt) {
					if (evt.keyCode == 27) {
						closeOffcanvas();
					}
				});


				offCanvasHandler.unbind("click");
				offCanvasHandler.on('click', function (e) {
					closeOffcanvas();
				});
				$(".oc-sub-panel").css("position", "absolute");

				if (currentObject && currentObject.length > 0) {
					currentObject.css("position", "relative");
				}

				var focusableElements = $offcanvas.find('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]');
				var lastFocusableElement = focusableElements ? focusableElements[focusableElements.length - 1] : '';

				$(lastFocusableElement).blur(function (e) {
					setTimeout(function() {
						if ($offcanvas.find("*:focus").length == 0){
							closeOffcanvas();
						}
					},30)
				});
			}

			function closeOffcanvas() {
				$('html').removeClass('off-canvas-open');
				$(document).off("touchmove");
				offCanvasLink.attr("aria-expanded", false);
				$offcanvas.removeClass("offCanvasScroll");
				offCanvasLink.trigger('focus');
			}


			offCanvasHandler.on('movestart', function (e) {
				// If the movestart is heading off in an upwards or downwards
				// direction, prevent it so that the browser scrolls normally.

				if ((e.distX > e.distY && e.distX < -e.distY) ||
					(e.distX < e.distY && e.distX > -e.distY)) {
					e.preventDefault();
				}
			});
			offCanvasHandler.on('swiperight', function (event) {
				event.preventDefault();
				showOffcanvas();
			});


			offCanvasLink.on('click', function (event) {
				event.preventDefault();
				showOffcanvas();
			});
			logoLink.on('focus', function (event) {
				if ($('html').hasClass('off-canvas-open')) {
					closeOffcanvas();
				}

			});

			if ($("#ocDataId").attr("data-value")) {
				var rootPage = $('#rootValue').data('root');

				$.get("/"+rootPage+"~mgnlArea=header~.json", {
				}, function(ocData){
					var ocDataJSON = JSON.parse(ocData);
					buildOffCanvasMenu(ocDataJSON);
				});
			}

			// end init
			$offcanvas.data(pluginName, pluginName);
		}

		function buildOffCanvasMenu(ocData) {

			/* Weiterführende Links nach der Navigation */
			var $linkList = $('<ul />');
			$linkList.addClass('oc-links');
			$offcanvas.append($linkList);
			$.each(ocData.links, function (index, linkObj) {
				if (linkObj.isHidden) {
					return; // Continue
				}

				var $listItem = $('<li />');
				$linkList.append($listItem);
				var $link = $('<a />');
				if (linkObj.active) {
					$link.addClass('oc-nav-active-link');
				}
				$listItem.append($link)
				$link.append('<span class="ic-arrow2-right" aria-hidden=\"true\"></span>');
				$link.append(linkObj.text);
                if (linkObj.href) {
                    $link.attr('href', linkObj.href);
                }
				if (linkObj.target) {
					$link.attr('target', linkObj.target);
				}
				if (linkObj.title) {
					$link.attr('title', linkObj.title);
				}
				if (linkObj.id) {
					$link.attr('id', linkObj.id);
				}

			});

			var $listItemClose = $('<li />');
			var closeText = document.documentElement.lang == 'de' ? 'Off-Canvas-Menü schließen' : 'Close off-canvas menu' ;
				$linkList.append($listItemClose);
				var $closeLink = $('<button />');
				$closeLink.addClass('hide-accessible show-on-focus oc-close-btn-accessibility')
				$listItemClose.append($closeLink)
				$closeLink.append(closeText);

			$closeLink.on('click', function(e) {
				e.preventDefault();
				closeOffcanvas();
			});

			/*  Navigation */
			var $navigation = $('<div style="position:relative;" />');
			//$offcanvas.append($navigation);
			$linkList.before($navigation);
			// 1. Navigations-Ebene
			var $navListLevel_1 = $('<ul />');
			$navListLevel_1.addClass('oc-nav');
			$navListLevel_1.attr('id', 'oc-nav-level-1');
			$navListLevel_1.attr('tabindex', '-1');

			$navigation.append($navListLevel_1);
			var channelId = $("#ocDataId").attr("data-value");

			$.each(ocData.navigation, function (index, linkObj) {
				if (linkObj.uuid == channelId ) {
					linkObj.active = true;
				}

				var $listItem = $('<li />');
				$navListLevel_1.append($listItem);
				var $linkBtnWrapper = $('<div />');
				$listItem.append($linkBtnWrapper);
				var $link = $('<a />');
				$linkBtnWrapper.append($link)

				if (linkObj.isLocked) {
					$link.append('<span>' + linkObj.text + ' <span class="ic-lock" aria-hidden="true"></span></span>');
				} else {
					$link.append('<span>' + linkObj.text + '</span>');
				}

				$link.attr('href', linkObj.href);
				if (linkObj.target) {
					$link.attr('target', linkObj.target);
				}

				// Erstelle Kindelemente
				var childElements = linkObj.children;
				if (lexicographicalOrder === true) {
					childElements.sort(function (leftHandSide, rightHandSide) {
						return leftHandSide.text > rightHandSide.text;
					});
				}
				if (childElements && childElements.length > 0) {
					var $childBtn = $('<button aria-label="'+showSubpagesButtonAriaLabel+'"><span class="ic-plus1" aria-hidden=\"true\"></span></button>');

					if (!linkObj.isSubLevelHidden) {
						$linkBtnWrapper.append($childBtn);
					}

					$childBtn.on('click', function (event) {
						event.preventDefault();
						if ($listItem.hasClass("active")) {

							$navListLevel_2.slideUp("fast", function () {
								$listItem.removeClass("active");
								$childBtn.html('<span aria-hidden="true" class="ic-plus1"></span>');
								$childBtn.attr("aria-label", showSubpagesButtonAriaLabel);
							});

						} else {
							$listItem.addClass("active");
							$navListLevel_2.slideDown("fast", function () {
								$childBtn.html('<span aria-hidden="true" class="ic-minus1"></span>');
								$childBtn.attr("aria-label", hideSubpagesButtonAriaLabel);
							})
						}

					});


					// 2. Navigations-Ebene
					var $navListLevel_2 = $('<ul />');
					$navListLevel_2.hide();

					$listItem.append($navListLevel_2);

					$.each(childElements, function (index2, linkObj2) {
						if (linkObj2.uuid == channelId ) {
							linkObj2.active = true;
							// linkObj.active = true;
						}

						if (linkObj2.isHidden) return; // Continue

						var $listItem2 = $('<li />');
						$navListLevel_2.append($listItem2);
						var $link2 = $('<a />');
						if (linkObj2.active) $link2.addClass('oc-nav-active-link');
						$listItem2.append($link2)

						if (linkObj2.isLocked) {
							$link2.append('<span>' + linkObj2.text + ' <span class="ic-lock" aria-hidden="true"></span></span>');
						} else {
							$link2.append('<span>' + linkObj2.text + '</span>');
						}

						$link2.attr('href', linkObj2.href);
						if (linkObj2.target) {
							$link2.attr('target', linkObj2.target);
						}

						if (linkObj2.children && linkObj2.children.length > 0) {
							var $childBtn2 = $('<button aria-label="'+showSubpagesButtonAriaLabel+'"><span class="ic-arrow1-right" aria-hidden=\"true\"></span></button>');

							if (!linkObj2.isSubLevelHidden) {
								$listItem2.append($childBtn2);
							}

							/*var $ocParentPanel = $navListLevel_1;
							var $ocBackBtn = $childBtn2;
							var parentLinkObj = linkObj2;*/

							createSubPanels($navigation, $navListLevel_1, $childBtn2, linkObj2, 2);
						}
					});

					if (linkObj.active && !linkObj.isSubLevelHidden) {
						$childBtn.trigger('click');
					}
				}
			});

			if (lastActiveLevel > 1) {
				//$('#oc-nav-level-1').css("visibility", "hidden");
				$('#oc-nav-level-1').css("position", "absolute");
				$('#oc-nav-level-1').css("left", "-250px");
				currentBtn.click();
			} else {
				$('#oc-nav-level-1').addClass('panel-visible');
			}
		}
	});
};
