$.fn.videoPreviewPlugin = function (options) {

	var pluginName = 'videoPreviewPlugin';

	var settings = $.extend({}, options);

	return this.each(function () {
		$(this).on('click', function () {
			var $template = $(this).find('.js-video-template');
			var templateHTML = $template.html();
			var constructionContainer = document.createElement('div');

			templateHTML = templateHTML.replace('?autoplay=0', '?autoplay=1'); // Activate autoplay for YT and vimeo because we use the preview image

			$(constructionContainer).html(templateHTML);
			$(constructionContainer).find('[data-framesrc]').each(function () { // IFrame
				$(this).removeClass('js-lazy-frame');
				$(this).attr('src', $(this).data('framesrc'));
			});
			$(constructionContainer).find('[data-src]').each(function () { // Video Element
				$(this).attr('src', $(this).data('src'));
				$(this).parent().attr('autoplay', true);
			});
			$(constructionContainer).find('[data-href]').each(function () { // Video Element
				$(this).attr('href', $(this).data('href'));
			});

			$(this).empty();
			$(this).html($(constructionContainer).html());
			$(this).removeClass('video-preview js-video-preview');
		});
    });

};

