$.fn.immoFilterPlugin = function (options) {
	var pluginName = "immoFilterPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
	}, options);
	// Do something to each element here

    var element,
        data,
        visibleData,
        searchagentData;

    var objectTypes = [{
            "name": "bueroflaechen",
            "title": "Büroflächen"
        }, {
            "name": "geschaeftslokale",
            "title": "Geschäftslokale"
        }, {
            "name": "grundstuecke",
            "title": "Grundstücke"
        }, {
            "name": "haeuser",
            "title": "Häuser"
        }, {
            "name": "lagergewerbeimmobilien",
            "title": "Lager- & Gewerbeimmobilien"
        }, {
            "name": "wohungen",
            "title": "Wohnungen"
        }, {
            "name": "sonstige",
            "title": "Sonstige Immobilien"
        }]

    
    return this.each(function () {
        element = this;

        getData();
    });

    function getObjectTypeTitle(objectName) {
        for(var i = 0; i < objectTypes.length; i ++) {
            if(objectTypes[i].name == objectName) {
                return objectTypes[i].title
            }
        }
    }

    function getData() {

        var rootPage = $('#rootValue').data('root');

        var url = rootPage.length > 0 ? "/"+rootPage+"/immo-data.js" : "/immo-data.js" ;

        $.get(url, {
        }, function(receivedData){
            data = JSON.parse(receivedData);
            
            setResultCount();
            initEventListener();
        });

    }

    function setResultCount() {
        var checkboxes = $('.immo-filter__list--business-types .immo-filter__checkbox');
   
        for(var i = 0; i < checkboxes.length; i ++) {
            var idName = $(checkboxes[i]).attr('id');
            var objectType = idName.split("_")[0]
            var businessType = idName.split("_")[1]

            var resultCounts = getCountOfObjectAndBusinessType(data, objectType, businessType);
            var htmlCounter = $('[for=' + idName + '] .immo-filter__result-count');
            htmlCounter.html(" (" + resultCounts + ")");
        }
    }

   
    function initEventListener() {
        $('.immo-filter__button--reset').on('click', function(event) {
            event.preventDefault();
            $(element).find('.immo-filter__checkbox').prop( "checked", false );
            filterResults();
        });

        $('.message__close').on('click', function(event) {
            event.preventDefault();
            $(this).parent('.message').addClass('message--disabled');
        });

        $('.immo-filter__button--toggler').on('click', function(event) {
            event.preventDefault();

            if($(element).hasClass('immo-filter--closed')) {
                openFilter();
            } else {
                closeFilter();
            }
        });

        $('.immo-filter__button--searchagent').on('click', function(event) {
            event.preventDefault();

            if(!searchagentData || (searchagentData.states.length <= 0 && searchagentData.objects.length <= 0)) {
                $(element).find('.message--disabled').removeClass('message--disabled');
                $(element).addClass('js-searchagent-disabled');
            } else {
                openSearchagentDialog();
            }
        });


        $('.immo-filter__checkbox--object-type').on('click', function() {
            var clickedCheckbox = $(this);
            var itemIsChecked = clickedCheckbox.prop("checked");

            if(itemIsChecked) {
                clickedCheckbox.parent().find('.immo-filter__checkbox').prop( "checked", true );
            } else {
                clickedCheckbox.parent().find('.immo-filter__checkbox').prop( "checked", false );
            }

            filterResults();
        });

        $('.immo-filter__checkbox').on('click', function() {
            $(element).removeClass('js-searchagent-disabled');
            var clickedCheckbox = $(this);
            var itemIsChecked = clickedCheckbox.prop("checked");

            var parentCheckbox = $(this).parents('.immo-filter__object-type').find('.immo-filter__checkbox--object-type');

            if (itemIsChecked && parentCheckbox.length > 0) {
                parentCheckbox.prop("checked", true);
            }
            filterResults();
        });

        initCloseButton();
    };

    function initCloseButton() {
        $('.js-searchagent-close').on('click', function(event) {
            event.preventDefault();

            $(element).removeClass('immo-filter--searchagent-opened');
        });
    }

    function openSearchagentDialog() {
        $(element).addClass('immo-filter--searchagent-opened');

        createFormHTML();
    }


    function createFormHTML() {
        var formUrl = $(element).data('formUrl');

        load(formUrl, function(xhr) {
            var data = JSON.parse(xhr.response);
            var form = $('<form action="'+ formUrl +'" method="post" />');

            var searchAgentTitleLabel = $('<label for="searchagentTitle" class="searchagent__label">Titel: *</label>');
            var searchAgentTitle = $('<input class="searchagent__input" type="text" required name="searchagentTitle" id="searchangentTitle" value="Suchagent - ' + getFormatedDate() + '" />');

            var searchAgentEmailLabel = $('<label for="searchagentEmail" class="searchagent__label">E-Mail: *</label>');
            var searchAgentEmail = $('<input class="searchagent__input" type="text" type="email" required name="searchagentEmail" id="searchagentEmail" />');

            var searchAgentData = $('<input class="searchagent__data" type="hidden" required name="searchagentProperties" />');
            searchAgentData.val(JSON.stringify(searchagentData));

            var searchAgentToken = $('<input class="searchagent__data" type="hidden" required name="xToken" />');
            searchAgentToken.val(data.xtoken);

            var searchagentButtons = $('<div class="searchagent__buttons" />');
            var cancleButton = $('<button class="searchagent__button md-button-secondary js-searchagent-close" type="button">Abbrechen</button>');
            var submitButton = $('<button class="searchagent__button md-button js-searchagent-submit" type="submit">Erstellen</button>');

            searchagentButtons.append(cancleButton);
            searchagentButtons.append(submitButton);

            form.append(searchAgentTitleLabel);
            form.append(searchAgentTitle);
            form.append("<br/>");
            form.append(searchAgentEmailLabel);
            form.append(searchAgentEmail);
            form.append(searchAgentData);
            form.append(searchAgentToken);
            form.append(searchagentButtons);

            $(element).find('.searchagent__inner').html(form);
            initCloseButton();

        }, function() {
            $(element).find('.searchagent__inner').html("<p>Derzeit steht der Suchagent nicht zur Verfügung, bitte probieren Sie es später erneut.</p>");
            console.log("searchagent could not be loaded");
        }, null);

    }

    function closeFilter() {
        $(element).find('.immo-filter__inner').slideUp();
        $(element).addClass('immo-filter--closed');
        updateButtonText();
    }

    function openFilter() {
        $(element).find('.immo-filter__inner').slideDown();
        $(element).removeClass('immo-filter--closed');

        updateButtonText();
    }

    function foundInObject(arrayInput, target) {
        for(var i = 0; i < arrayInput.length; i ++) {
            if(arrayInput[i]["businessTypes"].length) {
                for(var z = 0; z < arrayInput[i]["businessTypes"].length; z++ ) {
                    if (arrayInput[i]["objectType"] == target.objectType && $.inArray( target.businessType, arrayInput[i]["businessTypes"]) > -1) {
                        return true;
                    }
                }
            } else if (arrayInput[i]["objectType"] == target.objectType) {
                return true;
            }
        }
    }

    function filterResults() {
        var filterObjectTypes = $(element).find('.immo-filter__checkbox--object-type:checked').map(function(){
            var filterBusinessTypes = $(this).parent().find('.immo-filter__checkbox--business-type:checked');
            var $ele = $(this);

            return {
                "objectType": $ele.val(),
                "businessTypes": filterBusinessTypes.map(function(){
                    return $(this).val()
                }).get()
            }
          }).get();

        var filterState = $(element).find('.immo-filter__checkbox--state:checked').map(function(){
            return $(this).val();
          }).get();


        var filteredResult = data.filter(function(item) {
            if (filterState.length && filterObjectTypes.length) {
                return foundInObject(filterObjectTypes, item) && $.inArray( item.state, filterState) > -1;
            } else if (filterState.length) {
                return $.inArray( item.state, filterState) > -1;
            } else if (filterObjectTypes.length) {
                return foundInObject(filterObjectTypes, item);
            } else {
                return item;
            }
        });

        renderResults(filteredResult);

        searchagentData = {
            "states": filterState,
            "objects": filterObjectTypes
        }
    }

    function renderResults(result) {
        if($(element).find(':checked'))

        visibleData = result;

        $('#filter-results').empty();
        var url = window.location.href.split('.html')[0];

        for (var i = 0; i < result.length; i++) {
            var item = result[i];
            var $li = $('<li />');
            $li.addClass('col-1 mup-col-1');

            var $img = $('<img />');
            $img.attr('src', item.imageSrc);
            $img.attr('data-src', item.imageSrc);
            $img.attr('data-srcset', item.imageSrcset);
            $img.attr('srcset', item.imageSrcset);
            $img.addClass('center');

            var $link = $('<a />');
            $link.addClass('mup-content');
            $link.attr('href', url + "~" + item.name + "~");

            var $wrapper = $('<div />')

            var $type = $('<h3 />')
            $type.addClass('immo-mup-up white-header');
            $type.html(getObjectTypeTitle(item.objectType));
            $wrapper.append($type);

            var $title = $('<div />')
            $title.addClass('mup-multiline-truncation');
            $title.html(item.title)
            $wrapper.append($title);

            var $size = $('<h3 />')
            $size.addClass('immo-mup-down');
            $size.html(item.size)
            $wrapper.append($size);

            var $price = $('<h3 />')
            $price.addClass('immo-mup-down immo-mup-down-right');
            $price.html(item.price)
            $wrapper.append($price);


            $link.append($wrapper);

            $li.append($img);
            $li.append($link);


            $('#filter-results').append($li);
        }

        if(result.length <= 0) {
            var li = $('<li />');
            li.addClass('no-result')
            li.html("Derzeit sind leider keine Objekte mit eingestelltem Filter vorhanden.");
            $('#filter-results').append(li);
        }

        if(!$(element).hasClass('immo-filter--closed')) {
            updateButtonText();
        }
    }

    function updateButtonText() {
        if($(element).hasClass('immo-filter--closed')) {
            $('.immo-filter__button--toggler').html("<span aria-hidden=\"true\" class='ic-arrow1-down'></span> Filter öffnen")
        } else {
            $('.immo-filter__button--toggler').html("<span aria-hidden=\"true\" class='ic-arrow1-up'></span> " + visibleData.length + (visibleData.length > 1 ? " Ergebnisse" : " Ergebnis") + " anzeigen")
        }
    }

    function getCountOfObjectAndBusinessType(items, objectType, businessType) {
        var count = 0;

        for(var i = 0; i < items.length; i++) {
            if(items[i].objectType == objectType && items[i].businessType == businessType) {
                count ++;
            }
        }

        return count;
    }

    function getFormatedDate() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '.' + mm + '.' + yyyy;

        return today;
    }

    function sendRequest(requestType, url, callback, callbackError, requestHeader, params) {
        var xhr = new XMLHttpRequest();

        xhr.open(requestType, encodeURI(url));
        if(requestHeader) {
            for(var i = 0; i < requestHeader.length; i++) {
                var objKey = Object.keys(requestHeader[i])[0];
                xhr.setRequestHeader(objKey, requestHeader[i][objKey]);
            }
        }

        xhr.onerror = function (ev) {
            callbackError(ev)
        }
        xhr.onreadystatechange = ensureReadiness;

        function ensureReadiness() {
            if (xhr.readyState < 4) {
                return;
            }

            if (xhr.status !== 200) {
                return;
            }

            // all is well
            if (xhr.readyState === 4) {
                callback(xhr);
            }
        }

        xhr.send(params);
    }

    function load(url, callback, callbackError, requestHeader, params) {
        sendRequest('GET', url, callback, callbackError, requestHeader, params);
    }

};
