
$.fn.waterfallVideosDisclaimerPlugin = function (options) {

	var pluginName = "waterfallVideosDisclaimerPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
		// color: "#556b2f",
		// backgroundColor: "white"
	}, options);
	// Do something to each element here.

	var sequenzes;
	var sliders;

	return this.each(function () {
		prepareYTVideos();
		toggleYTVIdeos();
	});

	function prepareYTVideos () {
		if ($('.waterfall-sequenz__bg--video').length > 0) {
			var disclaimer = document.createElement('div');
			disclaimer.classList.add('waterfall-youtube-disclaimer');

			var disclaimerCloseBtn = document.createElement('button');
			disclaimerCloseBtn.classList.add('waterfall-youtube-disclaimer_close-button');
			disclaimerCloseBtn.innerHTML = "<span class='ic-close2'></span><span class='hide-accessible'>Schließen</span>";
			disclaimerCloseBtn.addEventListener('click', function(e) {
				e.preventDefault();
				document.querySelector('.waterfall-youtube-disclaimer').classList.add('waterfall-youtube-disclaimer--closed');
			});
			disclaimer.appendChild(disclaimerCloseBtn);

			var disclaimerTxt = document.createElement('div');
			disclaimerTxt.classList.add('waterfall-youtube-disclaimer_text');
			disclaimerTxt.innerHTML = "<p>" + getPropertyText("disclaimerText") + "</p>";
			disclaimer.appendChild(disclaimerTxt);

			var disclaimerSubmit = document.createElement('button');
			disclaimerSubmit.classList.add('md-button');
			disclaimerSubmit.classList.add('video-disclaimer-btn');
			disclaimerSubmit.addEventListener('click', function(e) {
				e.preventDefault();
				setLocalStorage();
				toggleYTVIdeos();
				$('body').videoDisclaimerPlugin();
			});
			disclaimer.appendChild(disclaimerSubmit);

			var disclaimerMoreInfo = document.createElement('button');
			disclaimerMoreInfo.classList.add('md-button');
			disclaimerMoreInfo.classList.add('md-button--link');
			disclaimerMoreInfo.classList.add('video-disclaimer-more-btn');
			disclaimerMoreInfo.textContent = getPropertyText("showMore");
			disclaimer.appendChild(disclaimerMoreInfo);

			disclaimerMoreInfo.addEventListener('click', function(e) {
				e.preventDefault();

				if ($('.waterfall-youtube-disclaimer').hasClass('waterfall-youtube-disclaimer--open')) {
					$('.waterfall-youtube-disclaimer').removeClass('waterfall-youtube-disclaimer--open');
					$('.video-disclaimer-more-btn').html(getPropertyText('showMore'));

				} else {
					$('.waterfall-youtube-disclaimer').addClass('waterfall-youtube-disclaimer--open');
					$('.video-disclaimer-more-btn').html(getPropertyText('showLess'));
				}

			});

			document.querySelector('body').appendChild(disclaimer);
		}
	}

	function toggleYTVIdeos () {
		var yTIsAllowed = videosAreEnabled();
		var allBGVideos = document.querySelectorAll('.waterfall-sequenz__bg--video');

		if (yTIsAllowed) {
			$('body').addClass('videos-enabled');
			$('.waterfall-sequenz__bg--video').removeClass('video-disabled');
			$('.video-disclaimer-btn').html(getPropertyText("rejectButton"));

			for (var i = 0; i < allBGVideos.length; i++) {
				var bgVideo = allBGVideos[i];
				if (!bgVideo.parentNode.classList.contains('js-lazy-waterfall-content')) {
					var frame = bgVideo.querySelector('iframe');
					frame.setAttribute('src', bgVideo.getAttribute('data-video-url'));
				}
			}
		} else {
			$('body').removeClass('videos-enabled');
			$('.waterfall-sequenz__bg--video').addClass('video-disabled');
			$('.video-disclaimer-btn').html(getPropertyText("acceptButton"));

			for (var i = 0; i < allBGVideos.length; i++) {
				var frame = allBGVideos[i].querySelector('iframe');
				frame.removeAttribute('src');
			}
		}
	}

	function videosAreEnabled() {
		return localStorage.getItem('oebb-yt-videos') ? true : false;
	}

	function setLocalStorage() {
		if (videosAreEnabled()) {
			localStorage.removeItem('oebb-yt-videos');
		}  else {
			localStorage.setItem('oebb-yt-videos', "true");
		}
	}

	function getPropertyText(property) {
		var i18nTexts = $.fn.i18nPlugin();
		var lang =  document.querySelector("html").getAttribute("lang");

		return i18nTexts[property] ? (i18nTexts[property][lang] ? i18nTexts[property][lang] : i18nTexts[property]["en"] ) : "";
	}

};
