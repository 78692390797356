/**
 * Created by z211567 on 26.05.2015.
 */

$.fn.tabsPlugin = function (options) {
	var pluginName = "tabsPlugin";

	// default options.
	var $html = $("html");
	var lang = $html.attr("lang") != 'undefined' ?  $html.attr("lang") : "de";
	var settings = $.extend({
		wrapMargin: 10,
		language: {
			moreTabs: "Mehr"
		},
		defaultActive: 0,
		useParams: false
	}, options);

	//language settings
	if(lang == 'en') {
		settings.language = {
			moreTabs: "More"
		}
	}
	// Do something to each element here.


	return this.each(function () {
		var $tabs = $(this);

		if (!$tabs.data(pluginName)) {

			var currentListItem;
			var currentHandlerListItem;
			var content = $(".tab-container");
			content.css("overflow-x", "hidden");

			function resetDefaultVersion() {
				$tabs.removeClass("tabs-default");
				var tabHandlerList = $tabs.prevAll(".tab-handler");
				var tabHandlerDropDownList = $tabs.prevAll(".tab-handler-dropdown");

				tabHandlerList.remove();
				tabHandlerDropDownList.remove();
			}

			function createDefaultVersion() {
				$tabs.addClass("tabs-default");
				var tabHandlerList = $("<ul class=\"tab-handler\" />");
				var tabHandlerDropDownList = $("<ul class=\"tab-handler-dropdown\" />");
				$tabs.before(tabHandlerList);
				tabHandlerList.after(tabHandlerDropDownList);
				tabHandlerDropDownList.hide();
				var elementsHidden = false;
				$.each($tabs.children("li"), function (index, listItem) {
					var $listItem = $(listItem);
					$listItem.attr("aria-label", $listItem.find(".tab-headline").text());
					$listItem.attr("tabindex", -1);

					var tabHandlerListItem = $("<li />");
					tabHandlerList.append(tabHandlerListItem);
					var tabHandlerLink = $("<a />");
					tabHandlerListItem.append(tabHandlerLink);

					var tabItemId = idGen.getId();
					tabHandlerLink.attr("aria-controls", tabItemId);
					tabHandlerLink.attr("href", "#" + tabItemId);
					tabHandlerLink.attr("aria-expanded", false);
					tabHandlerLink.append($listItem.find(".tab-headline").first());
					$listItem.attr("id", tabItemId);
					if (index == settings.defaultActive) {
						$listItem.addClass("open");
						currentListItem = $listItem;
						tabHandlerLink.attr("aria-expanded", true);
						tabHandlerListItem.addClass("active");
						currentHandlerListItem = tabHandlerListItem;
					}



					tabHandlerLink.on('click', function (event) {
						var tabbing = function(e) {
							if (e.keyCode !== 9 || (e.shiftKey && e.keyCode == 9)) {
								return;
							}

							e.preventDefault();

							var firstLink = currentListItem.find('a').first()
							firstLink.focus();
							firstLink.on('keydown.test', function(e) {
								if (!(e.shiftKey && e.keyCode == 9)) return;
                                    e.preventDefault();
                                    tabHandlerLink.focus();
							});

							tabHandlerLink.off('keydown.tabTabbing');
						};


						event.preventDefault();
						if (! $listItem.hasClass("open")) {
							if (currentListItem) {
								currentListItem.removeClass("open");
								tabHandlerList.find("a[aria-expanded='true']").attr("aria-expanded", "false");
								tabHandlerLink.attr("aria-expanded", true);
								currentListItem = $listItem;
								currentListItem.addClass("open");
								currentHandlerListItem.removeClass("active");
								currentHandlerListItem = tabHandlerListItem;
								currentHandlerListItem.addClass("active");


							} else {
								currentListItem = $listItem;
								currentListItem.addClass("open");
								tabHandlerLink.attr("aria-expanded", true);
								currentHandlerListItem = tabHandlerListItem;
								currentHandlerListItem.addClass("active");
							}

							if (settings.useParams) {
								var tabHeadline = tabHandlerListItem.find('.tab-headline');
								var paramText = tabHeadline.length > 0 ? tabHeadline.text().toLowerCase() : "";

								var url = updateQueryStringParameter(document.location.href, 't', paramText)

								window.history.pushState("Applikationen & Links", paramText, url);
							}


							tabHandlerLink.on('keydown.tabTabbing', tabbing);
						}

						tabHandlerDropDownList.hide();
						setTabsAriaLabel();
					});

					if (tabHandlerListItem.position().left + tabHandlerListItem.outerWidth(true) + settings.wrapMargin > content.innerWidth()) {
						// tabHandlerListItem.hide();
						tabHandlerDropDownList.append(tabHandlerListItem);
						elementsHidden = true;
					}


				});

				var tabHandlerListItem = $("<li class='dropdown' />");
				tabHandlerList.append(tabHandlerListItem);
				var tabHandlerLink = $("<a aria-expanded=\"false\">" + settings.language.moreTabs + " <span class=\"ic-arrow1-down\" aria-hidden=\"true\"></span></a>");
				tabHandlerListItem.append(tabHandlerLink);
				var itemId = idGen.getId();
				tabHandlerDropDownList.attr("id", itemId);
				tabHandlerDropDownList.attr("role", "menu");
				tabHandlerDropDownList.attr("tabindex", -1);
				tabHandlerLink.attr("aria-controls", itemId);
				tabHandlerLink.attr("href", '#' + itemId);

				if (!elementsHidden) {
					tabHandlerListItem.hide();
				}
				tabHandlerLink.on('click', function (event) {
					event.preventDefault();
					if (tabHandlerListItem.hasClass("active")) {
						tabHandlerListItem.removeClass("active");
						tabHandlerDropDownList.hide();
						tabHandlerLink.attr("aria-expanded", "false");

					} else {
						tabHandlerListItem.addClass("active");
						tabHandlerLink.attr("aria-expanded", "true");
						tabHandlerDropDownList.focus();
						tabHandlerDropDownList.show();
						var handlerPosition = tabHandlerListItem.position();

						tabHandlerDropDownList.css("top", handlerPosition.top + tabHandlerListItem.outerHeight());
						tabHandlerDropDownList.css("left", handlerPosition.left - 210 + tabHandlerListItem.outerWidth() - 2);

						tabHandlerDropDownList.find("a").last().blur(function () {
							tabHandlerListItem.removeClass("active");
							tabHandlerDropDownList.hide();
							tabHandlerLink.attr("aria-expanded", "false");

						})
					}


				});

				// Test ob noch alle Tabs auf der Seite Platz haben.
				var checkTabs = function () {
					console.log('chck');

					var tabHandler = tabHandlerList.children("li");
					var dropDownHandler = tabHandlerDropDownList.children("li");
					//	//console.log(tabHandler);
					var dropDownItem = tabHandlerList.find(".dropdown").first();
					$.each($tabs.children("li"), function (index) {
						if (tabHandler[index] && !$(tabHandler[index]).hasClass("dropdown")) {
							// Normaler Tab

							var myItem = $(tabHandler[index]);

							if (myItem.position().left + myItem.outerWidth(true) + 90 > content.innerWidth()) {

								tabHandlerDropDownList.prepend(myItem.remove());
								console.log('in');


								if (tabHandlerDropDownList.children("li").length > 0) {
									dropDownItem.show();
								}
							}
						} else {
							// Tab ist in Drop Down

							if (dropDownItem[0] && dropDownItem.position().left + dropDownItem.outerWidth(true) + 160 < content.innerWidth()) {
								console.log(dropDownItem.position().left, dropDownItem.outerWidth(true) + 160, '<', content.innerWidth());

								if (tabHandlerList) {
									dropDownItem.before(dropDownHandler.splice(0, 1));
									checkTabs(); // Check again, because of softhyphens or breaks it is possible that the width is off

									if (tabHandlerDropDownList.children("li").length == 0) {
										dropDownItem.hide();
									}
								}
							}
						}
					});
				};

				var lastCheck = $(window).width();
				$(window).on('resize', function () {
					tabHandlerListItem.removeClass("active");
					tabHandlerDropDownList.hide();
					if ($(window).width() < lastCheck - 60 || $(window).width() > lastCheck + 60) {

						checkTabs();
						lastCheck = $(window).width()
					}

				});

				setTabsAriaLabel();
			}

			function createSmartphoneVersion() {
				$tabs.addClass("tabs-as-accordeon");
				$.each($tabs.children("li"), function (index, listItem) {
					var $listItem = $(listItem);


					var $handler = $("<a />");
					$listItem.prepend($handler);
					var itemId = idGen.getId();
					$handler.attr("aria-controls", itemId);
					$handler.attr("href", "#" + itemId);
					$handler.attr("aria-expanded", false);
					$handler.append($("<span class=\"ic-plus3\" aria-hidden=\"true\"></span>"));
					$handler.append($listItem.find(".tab-headline").first());

					var listItemContent = $listItem.children("div").first();
					listItemContent.attr("id", itemId);

					listItemContent.attr("tabindex", -1);

					$handler.on('click', function (event) {
						event.preventDefault();
						if ($listItem.hasClass("open")) {
							currentListItem.removeClass("open");
							$handler.attr("aria-expanded", false);
						}
						else if (currentListItem) {
							currentListItem.removeClass("open");
							currentListItem.find("a").first().attr("aria-expanded", 'false');
							currentListItem = $listItem;
							currentListItem.addClass("open");
							$handler.attr("aria-expanded", true);
							listItemContent.focus();
						} else {
							currentListItem = $listItem;
							currentListItem.addClass("open");
							$handler.attr("aria-expanded", true);
							listItemContent.focus();
						}

						setTabsAriaLabel();
					});

				});

				setTabsAriaLabel();
			}

			function resetSmartphoneVersion() {
				$tabs.removeClass("tabs-as-accordeon");
				$.each($tabs.children("li"), function (index, listItem) {
					var $listItem = $(listItem);
					var $handler = $listItem.find("a").first();
					$handler.after($listItem.find(".tab-headline").first());
					$handler.remove();


				});
			}

			function setTabsAriaLabel(){
				var activeTabText = $("ul.tab-handler li.active").not(".dropdown").length > 0 ? $("ul.tab-handler li.active").not(".dropdown").text().trim() : $("ul.tab-handler-dropdown li.active").text().trim();
				$tabs.attr("aria-label", activeTabText);
			}

			if ($(window).width() > 767) {
				/* Tablet,- und Deskop-Version: Die Elemente werden als Tabs dargestellt. */

				// Because of defered scripts we need to wait until Rendering, 10 ms seems to be good
				setTimeout(function () {
					createDefaultVersion();
				}, 10);
				//  resetDefaultVersion();
			} else {
				/* Smartphone-Version: Die Elemente werden als Klappbox dargestellt. */
				createSmartphoneVersion();
				// resetSmartphoneVersion();
				// createDefaultVersion();
			}

			$tabs.data(pluginName, true);
		}

		function updateQueryStringParameter(uri, key, value) {
			var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
			var separator = uri.indexOf('?') !== -1 ? "&" : "?";
			if (uri.match(re)) {
			  return uri.replace(re, '$1' + key + "=" + value + '$2');
			}
			else {
			  return uri + separator + key + "=" + value;
			}
		  }


	});


};
