var quizJSON = {
    "info": {
        "name":    "Südstrecken Quiz",
        "main":    "Testen Sie Ihr Wissen zur neuen Südstrecke und erfahren Sie mehr.",
        "results": " ",
        "level1":  " ",
        "level2":  " ",
        "level3":  " ",
        "level4":  " ",
        "level5":  " " // no comma here
    },
    "questions": [{
        "q": "Welche Bundesländer durchquert die Südstrecke?",
        "a": [{
            "option": "Wien, Steiermark, Kärnten\n",
            "correct": false
        }, {
            "option": "Niederösterreich, Wien, Steiermark, Kärnten",
            "correct": true
        }, {
            "option": "Niederösterreich, Wien, Burgenland, Steiermark",
            "correct": false
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>Niederösterreich, Wien, Steiermark, Kärnten</b><br>In Zukunft sind bessere Verbindungen möglich – zwischen vier Bundesländern und drei Landeshauptstädten. Im Einzugsgebiet der Südstrecke leben auf einer Gesamtlänge von 470 Kilometern ca. 3,5 Millionen Menschen. </p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>Niederösterreich, Wien, Steiermark, Kärnten</b><br>In Zukunft sind bessere Verbindungen möglich – zwischen vier Bundesländern und drei Landeshauptstädten. Im Einzugsgebiet der Südstrecke leben auf einer Gesamtlänge von 470 Kilometern ca. 3,5 Millionen Menschen. </p>"
    }, {
        "q": "Was bedeutet Abteufen?",
        "a": [{
            "option": "Eröffnungsfeier eines neuen Tunnels",
            "correct": false
        }, {
            "option": "Offizielle Premierenfahrt durch einen neuen Tunnel",
            "correct": false
        }, {
            "option": "Herstellen eines Schachtes von oben nach unten",
            "correct": true
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>Herstellen eines Schachtes von oben nach unten</b><br>Das Abteufen gehört zu den wagnisreichsten bergmännischen Arbeiten. Der Begriff \"Abteufen\" kommt aus der Bergmannsprache von \"Teufe\", was \"Tiefe\" bedeutet.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>Herstellen eines Schachtes von oben nach unten</b><br>Das Abteufen gehört zu den wagnisreichsten bergmännischen Arbeiten. Der Begriff \"Abteufen\" kommt aus der Bergmannsprache von \"Teufe\", was \"Tiefe\" bedeutet.</p>"
    }, {
        "q": "Was sind Tübbinge?",
        "a": [{
            "option": "Hochwertige Verbindungsbolzen",
            "correct": false
        }, {
            "option": "Maßeinheit in der Trassierung",
            "correct": false
        }, {
            "option": "Stütz-Ringsegmente im Tunnelbau",
            "correct": true
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>Stütz-Ringsegmente im Tunnelbau</b><br>Die tonnenschweren Stahlbetonteile werden auf der Baustelle vorgefertigt und mit der Stollenbahn zur Tunnelbohrmaschine geliefert, die sie unmittelbar nach dem Bohren im Tunnel einbaut, um die Wände zu stützen.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>Stütz-Ringsegmente im Tunnelbau</b><br>Die tonnenschweren Stahlbetonteile werden auf der Baustelle vorgefertigt und mit der Stollenbahn zur Tunnelbohrmaschine geliefert, die sie unmittelbar nach dem Bohren im Tunnel einbaut, um die Wände zu stützen.</p>"
    }, {
        "q": "Wie lange braucht man in Zukunft von Graz nach Klagenfurt?",
        "a": [{
            "option": "90 Minuten",
            "correct": false
        }, {
            "option": "45 Minuten",
            "correct": true
        }, {
            "option": "65 Minuten",
            "correct": false
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>45 Minuten</b><br>Davon profitieren Pendler, dadurch gewinnen die Betriebe. Die Koralmbahn bringt Menschen und Güter schneller weiter, in kürzeren Zeiten und in kürzeren Intervallen. Mit der Fertigstellung 2023 braucht man für die Zugfahrt von Graz nach Klagenfurt statt zwei Stunden Busreise nur mehr sage und schreibe 45 Minuten.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>45 Minuten</b><br>Davon profitieren Pendler, dadurch gewinnen die Betriebe. Die Koralmbahn bringt Menschen und Güter schneller weiter, in kürzeren Zeiten und in kürzeren Intervallen. Mit der Fertigstellung 2023 braucht man für die Zugfahrt von Graz nach Klagenfurt statt zwei Stunden Busreise nur mehr sage und schreibe 45 Minuten.\n</p>"
    }, {
        "q": "Um wieviel verkürzt sich die Fahrzeit von Wien nach Klagenfurt?",
        "a": [{
            "option": "1 h 20 min\n            ",
            "correct": true
        }, {
            "option": "45 min",
            "correct": false
        }, {
            "option": "3 h",
            "correct": false
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>1 h 20 min</b><br>Auf der neuen Strecke kommen Fahrgäste und Güter schneller ans Ziel: Die Südstrecke ist eines der spektakulärsten\n                Infrastrukturprojekte der nächsten Jahrzehnte. 200 Kilometer Bahnlinie werden modernisiert, 170 neu gebaut,\n                80 km neue Tunnel und über 150 Brücken und Unterführungen neu errichtet.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>1 h 20 min</b><br>Auf der neuen Strecke kommen Fahrgäste und Güter schneller ans Ziel: Die Südstrecke ist eines der spektakulärsten\n                Infrastrukturprojekte der nächsten Jahrzehnte. 200 Kilometer Bahnlinie werden modernisiert, 170 neu gebaut,\n                80 km neue Tunnel und über 150 Brücken und Unterführungen neu errichtet.</p>"
    }, {
        "q": "Welche Zugstrecke wird von der EU favorisiert und mitfinanziert?",
        "a": [{
            "option": "die Verkehrsachse Berlin – Palermo",
            "correct": false
        }, {
            "option": "die Hochgeschwindigkeits-Eisenbahnachse Paris – London",
            "correct": false
        }, {
            "option": "der Baltisch-Adriatische Korridor Danzig – Bologna",
            "correct": true
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>der Baltisch-Adriatische Korridor Danzig – Bologna</b><br>Die ÖBB-Infrastruktur AG arbeitet entlang dieses Korridors von Norden nach Süden. Zwischen Ostsee und Adria\n                gelegen, umfasst er 1.700 Kilometer. Die Aufnahme der Südstrecke in diese wichtige transeuropäische Verbindung\n                bedeutet für Österreich ein optimale Anbindung an aufstrebende Wirtschaftsräume und an die wichtigsten Seehäfen.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>der Baltisch-Adriatische Korridor Danzig – Bologna</b><br>Die ÖBB-Infrastruktur AG arbeitet entlang dieses Korridors von Norden nach Süden. Zwischen Ostsee und Adria\n                gelegen, umfasst er 1.700 Kilometer. Die Aufnahme der Südstrecke in diese wichtige transeuropäische Verbindung\n                bedeutet für Österreich ein optimale Anbindung an aufstrebende Wirtschaftsräume und an die wichtigsten Seehäfen.</p>"
    }, {
        "q": "Wie wird der Koralmtunnel vorwiegend gebaut?",
        "a": [{
            "option": "im zyklischen Vortrieb – Baggern und Sprengen",
            "correct": false
        }, {
            "option": "im kontinuierlichen Vortrieb – Tunnelbohrmaschine",
            "correct": true
        }, {
            "option": "im Gefrierverfahren – zirkulierende Kälteträger",
            "correct": false
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>im kontinuierlichen Vortrieb – Tunnelbohrmaschine</b><br>Beim kontinuierlichen Tunnelvortrieb gräbt sich der Bohrkopf einer Tunnelbohrmaschine durch das Gestein. Mit\n                10.000 PS stemmt die Maschine sich gegen Felsen und Geröll. Sie gräbt und schraubt sich immer tiefer hinein.\n                Fast rund um die Uhr tut sie das, seit dem ersten Tunnelanschlag 2009.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>im kontinuierlichen Vortrieb – Tunnelbohrmaschine</b><br>Beim kontinuierlichen Tunnelvortrieb gräbt sich der Bohrkopf einer Tunnelbohrmaschine durch das Gestein. Mit\n                10.000 PS stemmt die Maschine sich gegen Felsen und Geröll. Sie gräbt und schraubt sich immer tiefer hinein.\n                Fast rund um die Uhr tut sie das, seit dem ersten Tunnelanschlag 2009.</p>"
    }, {
        "q": "Der Semmering-Basistunnel verbindet ...",
        "a": [{
            "option": "Neunkirchen (NÖ) mit Spielberg (Stmk.)",
            "correct": false
        }, {
            "option": "Deutschlandsberg (Stmk.) mit dem Lavanttal (Ktn.)",
            "correct": false
        }, {
            "option": "Gloggnitz (NÖ) mit Mürzzuschlag (Stmk.)",
            "correct": true
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>Gloggnitz (NÖ) mit Mürzzuschlag (Stmk.)</b><br>Vom niederösterreichischen Gloggnitz ins steirische Mürzzuschlag führt durch das Gebirgsmassiv des Semmering\n                der Semmering-Basistunnel. Er ist exakt 27,3 Kilometer lang und schafft mit seinen beiden Tunnelröhren die Voraussetzung\n                für einen modernen Personen- und Güterverkehr.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>Gloggnitz (NÖ) mit Mürzzuschlag (Stmk.)</b><br>Vom niederösterreichischen Gloggnitz ins steirische Mürzzuschlag führt durch das Gebirgsmassiv des Semmering\n                der Semmering-Basistunnel. Er ist exakt 27,3 Kilometer lang und schafft mit seinen beiden Tunnelröhren die Voraussetzung\n                für einen modernen Personen- und Güterverkehr.</p>"
    }, {
        "q": "Was passiert mit der historischen Semmeringbahn?",
        "a": [{
            "option": "bleibt in Betrieb",
            "correct": true
        }, {
            "option": "wird stillgelegt",
            "correct": false
        }, {
            "option": "wird in einen Radweg umgebaut",
            "correct": false
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>bleibt in Betrieb</b><br>Die weltweit erste Hochgebirgsbahn wird laufend saniert, um den Fahrbetrieb aufrechterhalten zu können. Die zum\n                UNESCO-Weltkulturerbe erklärte Strecke ist und bleibt ein beliebtes Ausflugsziel.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>bleibt in Betrieb</b><br>Die weltweit erste Hochgebirgsbahn wird laufend saniert, um den Fahrbetrieb aufrechterhalten zu können. Die zum\n                UNESCO-Weltkulturerbe erklärte Strecke ist und bleibt ein beliebtes Ausflugsziel.</p>"
    }, {
        "q": "Wieviele Menschen betreten Tag für Tag den Grazer Hauptbahnhof?",
        "a": [{
            "option": "41.000",
            "correct": true
        }, {
            "option": "25.000",
            "correct": false
        }, {
            "option": "63.000",
            "correct": false
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>41.000</b><br>Nach Wien wird Graz ein zweiter internationaler Verkehrsknoten. 41.000 Personen kommen an oder fahren ab,\n                warten auf Reisende oder begleiten sie. Mit der Fertigstellung der Koralmbahn 2023 wird der Grazer Bahnhof\n                bedeutender, auch für den internationalen Verkehr, denn er liegt auf einer der wichtigsten Nord-Süd-Transversalen\n                Europas.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>41.000</b><br>Nach Wien wird Graz ein zweiter internationaler Verkehrsknoten. 41.000 Personen kommen an oder fahren ab,\n                warten auf Reisende oder begleiten sie. Mit der Fertigstellung der Koralmbahn 2023 wird der Grazer Bahnhof\n                bedeutender, auch für den internationalen Verkehr, denn er liegt auf einer der wichtigsten Nord-Süd-Transversalen\n                Europas.</p>"
    }, {
        "q": "Der Koralmtunnel ist ...",
        "a": [{
            "option": "der neueste Eisenbahntunnel der Welt",
            "correct": false
        }, {
            "option": "der sechstlängste Eisenbahntunnel der Welt",
            "correct": true
        }, {
            "option": "der längste Eisenbahntunnel Mitteleuropas",
            "correct": false
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>Der sechstlängste Eisenbahntunnel der Welt</b><br>Der Koralmtunnel ist 32,9 Kilometer lang. Er ist der sechslängste Eisenbahntunnel der Welt und verknüpft die Steiermark mit Kärnten, das Gebiet um Deutschlandsberg mit dem mittleren Lavanttal. In einer maximalen Tiefe von\n                1,2 Kilometern durchstoßen zwei parallel laufende Tunnelröhren, die je zehn Meter Außendurchmesser haben, das\n                Gebirgsmassiv der Koralpe.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>Der sechstlängste Eisenbahntunnel der Welt</b><br>Der Koralmtunnel ist 32,9 Kilometer lang. Er ist der sechslängste Eisenbahntunnel der Welt und verknüpft die Steiermark\n                mit Kärnten, das Gebiet um Deutschlandsberg mit dem mittleren Lavanttal. In einer maximalen Tiefe von\n                1,2 Kilometern durchstoßen zwei parallel laufende Tunnelröhren, die je zehn Meter Außendurchmesser haben, das\n                Gebirgsmassiv der Koralpe.</p>"
    }, {
        "q": "Wie groß ist das neue Areal Hauptbahnhof Wien?",
        "a": [{
            "option": "109 ha",
            "correct": true
        }, {
            "option": "18 ha",
            "correct": false
        }, {
            "option": "66 ha",
            "correct": false
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>109 ha</b><br>Mit 109 Hektar Fläche ist das Gesamtprojekt Hauptbahnhof Wien eines der größten Bauvorhaben der letzten Jahrzehnte\nin Stadt und Land. Es verbindet einen Bahnhofsneubau mit der Entwicklung eines ganzen Stadtviertels. Die\nösterreichische Hauptstadt ist, mehr noch als zuvor, ein transeuropäischer Knotenpunkt geworden.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>109 ha</b><br>Mit 109 Hektar Fläche ist das Gesamtprojekt Hauptbahnhof Wien eines der größten Bauvorhaben der letzten Jahrzehnte\nin Stadt und Land. Es verbindet einen Bahnhofsneubau mit der Entwicklung eines ganzen Stadtviertels. Die\nösterreichische Hauptstadt ist, mehr noch als zuvor, ein transeuropäischer Knotenpunkt geworden.</p>"
    }, {
        "q": "Wieviele Wasser-Messstellen gibt es im Semmeringgebiet wegen der Tunnelbauarbeiten?",
        "a": [{
            "option": "keine, denn der Tunnel verläuft ohnehin viel tiefer als das Grundwasser",
            "correct": false
        }, {
            "option": "4.300, weil jede kleinste Veränderung des Bergwassers festgehalten wird",
            "correct": true
        }, {
            "option": "27, jeden Tunnelkilometer einen Messstelle",
            "correct": false
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>4.300 Messstellen</b><br>Ein ständiges Auge haben die zuständige Projektleitung, Naturschützer und Wissenschaftler auf das Bergwasser\ndes Semmering. Kleinste Veränderungen werden festgehalten. Man erstellt Karten, erfasst hunderte Quellen, Brunnen,\nBäche, man zieht Proben und bringt sie ins Labor. Oberstes Prinzip: Qualität und Quantität des Trinkwassers\nwerden gewahrt.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>4.300 Messstellen</b><br>Ein ständiges Auge haben die zuständige Projektleitung, Naturschützer und Wissenschaftler auf das Bergwasser\ndes Semmering. Kleinste Veränderungen werden festgehalten. Man erstellt Karten, erfasst hunderte Quellen, Brunnen,\nBäche, man zieht Proben und bringt sie ins Labor. Oberstes Prinzip: Qualität und Quantität des Trinkwassers\nwerden gewahrt.</p>"
    }, {
        "q": "Was bedeutet Renaturierung?",
        "a": [{
            "option": "die Ansiedelung von bedrohten Wildtieren",
            "correct": false
        }, {
            "option": "die Schaffung von Naherholungsmöglichkeiten für Anrainer",
            "correct": false
        }, {
            "option": "die Wiederherstellung von naturnahen Lebensräumen",
            "correct": true
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>Im Kärntner Lavanttal nahmen sich die Umweltexperten, Landschaftsplaner und Wasserbautechniker eine Renaturierungsmaßnahme\ngroßen Ausmaßes vor: sie verlegten 1,4 Kilometer der Lavant. 7.000 Zwetschken-, Nuss- und\nAhornbäume, Weiden, Eschen und Eichen wurden an der umgelegten Lavant gepflanzt.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>die Wiederherstellung von naturnahen Lebensräumen</b><br>Im Kärntner Lavanttal nahmen sich die Umweltexperten, Landschaftsplaner und Wasserbautechniker eine Renaturierungsmaßnahme\ngroßen Ausmaßes vor: sie verlegten 1,4 Kilometer der Lavant. 7.000 Zwetschken-, Nuss- und\nAhornbäume, Weiden, Eschen und Eichen wurden an der umgelegten Lavant gepflanzt.</p>"
    }, {
        "q": "Wo verläuft die längste gerade Bahnstrecke Österreichs?",
        "a": [{
            "option": "zwischen Wien und St. Pölten",
            "correct": false
        }, {
            "option": "zwischen Salzburg und Linz",
            "correct": false
        }, {
            "option": "zwischen Wien und Bratislava",
            "correct": true
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>zwischen Wien und Bratislava</b><br>Rund 37 Kilometer Bahnstrecke werden in den nächsten Jahren auf österreichischer Seite ausgebaut, um Wien und Bratislava mit zeitgemäßer, leistungsfähiger Bahninfrastruktur zu verbinden. Der Großteil der Strecke, nämlich 32,5 Kilometer, geht schnurstracks durch die Landschaft und ist damit die längste Gerade im österreichischen\nEisenbahnnetz.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>zwischen Wien und Bratislava</b><br>Rund 37 Kilometer Bahnstrecke werden in den nächsten Jahren auf österreichischer Seite ausgebaut, um Wien und Bratislava mit zeitgemäßer, leistungsfähiger Bahninfrastruktur zu verbinden. Der Großteil der Strecke, nämlich 32,5 Kilometer, geht schnurstracks durch die Landschaft und ist damit die längste Gerade im österreichischen\nEisenbahnnetz.</p>"
    }, {
        "q": "Was ist ein intermodaler Terminal?",
        "a": [{
            "option": "verschiedene Verkehrsmittel und Verkehrswege treffen an einem Punkt zusammen",
            "correct": true
        }, {
            "option": "ein internationaler Bahnhof mit Flughafenanbindung",
            "correct": false
        }, {
            "option": "ein komplexes Weichensystem auf Hochgeschwindigkeitsstrecken",
            "correct": false
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>verschiedene Verkehrsmittel und Verkehrswege treffen an einem Punkt zusammen</b><br>Im Süden von Wien errichtet die ÖBB-Infrastruktur das Güterzentrum Wien Süd. Dieser intermodale Terminal oder Umschlagplatz hat den Vorteil, dass verschiedene Verkehrsmittel und Verkehrswege an einem Punkt zusammentreffen. Beispielsweise wird ein und derselbe Container erst vom Zug und dann vom LKW transportiert oder umgekehrt.Man muss die Waren nicht einzeln ausladen, umladen und wieder einladen. Das spart Zeit und Geld.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>verschiedene Verkehrsmittel und Verkehrswege treffen an einem Punkt zusammen</b><br>Im Süden von Wien errichtet die ÖBB-Infrastruktur das Güterzentrum Wien Süd. Dieser intermodale Terminal oder Umschlagplatz hat den Vorteil, dass verschiedene Verkehrsmittel und Verkehrswege an einem Punkt zusammentreffen. Beispielsweise wird ein und derselbe Container erst vom Zug und dann vom LKW transportiert oder umgekehrt. Man muss die Waren nicht einzeln ausladen, umladen und wieder einladen. Das spart Zeit und Geld.</p>"
    }, {
        "q": "In welcher Form wird die Pottendorfer Linie modernisiert?",
        "a": [{
            "option": "als zweigleisige Hochleistungsstrecke",
            "correct": true
        }, {
            "option": "als historische Tourismus-Bahnstrecke",
            "correct": false
        }, {
            "option": "als reine Güterverkehrsachse",
            "correct": false
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>als zweigleisige Hochleistungsstrecke</b><br>Bis 2023 modernisiert die ÖBB-Infrastruktur die Pottendorfer Linie, eine 52 Kilometer lange Ergänzungsroute auf der Südstrecke. Diese zweite Route beginnt in Wien Meidling und führt nach Niederösterreich, über Wampersdorf, einen Ortsteil der namensgebenden Gemeinde Pottendorf, weiter nach Wiener Neustadt. Sie wird eine moderne,\nzweigleisige Hochleistungsstrecke und sie wird schneller; die Züge fahren mit bis zu 200 km/h auf den Schienen.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>als zweigleisige Hochleistungsstrecke</b><br>Bis 2023 modernisiert die ÖBB-Infrastruktur die Pottendorfer Linie, eine 52 Kilometer lange Ergänzungsroute auf der Südstrecke. Diese zweite Route beginnt in Wien Meidling und führt nach Niederösterreich, über Wampersdorf, einen Ortsteil der namensgebenden Gemeinde Pottendorf, weiter nach Wiener Neustadt. Sie wird eine moderne,\nzweigleisige Hochleistungsstrecke und sie wird schneller; die Züge fahren mit bis zu 200 km/h auf den Schienen.</p>"
    }, {
        "q": "Wie viele Arbeiter sind für die Südstrecke im Einsatz?",
        "a": [{
            "option": "750",
            "correct": false
        }, {
            "option": "3.500",
            "correct": false
        }, {
            "option": "über 5.000",
            "correct": true
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>über 5.000</b><br>Die Südstrecke ist eines der größten und spektakulärsten Infrastrukturprojekte der nächsten Jahrzehnte. 200 Kilometer Bahnlinie werden modernisiert, 170 neu gebaut. Über 5.000 Menschen arbeiten daran, weitere 15.000 sind\nhier in Zukunft beschäftigt. Auf der neuen Strecke rollen Millionen Tonnen Güter schneller und günstiger ans Ziel.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>über 5.000</b><br>Die Südstrecke ist eines der größten und spektakulärsten Infrastrukturprojekte der nächsten Jahrzehnte. 200 Kilometer Bahnlinie werden modernisiert, 170 neu gebaut. Über 5.000 Menschen arbeiten daran, weitere 15.000 sind\nhier in Zukunft beschäftigt. Auf der neuen Strecke rollen Millionen Tonnen Güter schneller und günstiger ans Ziel.</p>"
    }, {
        "q": "Welche Landeshauptstädte verbindet die Südstrecke?",
        "a": [{
            "option": "Wien – Eisenstadt – Klagenfurt",
            "correct": false
        }, {
            "option": "Wien – Eisenstadt – Graz",
            "correct": false
        }, {
            "option": "Wien – Graz – Klagenfurt",
            "correct": true
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>Wien – Graz – Klagenfurt</b><br>2026 eilen die Züge in nur 2 Stunden 40 Minuten von Wien nach Klagenfurt, in nur 1 Stunde 50 Minuten von Wien nach Graz und in nur 45 Minuten von Graz nach Klagenfurt.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>Wien – Graz – Klagenfurt</b><br>2026 eilen die Züge in nur 2 Stunden 40 Minuten von Wien nach Klagenfurt, in nur 1 Stunde 50 Minuten von Wien nach Graz und in nur 45 Minuten von Graz nach Klagenfurt.</p>"
    }, {
        "q": "Welche beiden Berge müssen auf der Südstrecke durchquert werden?",
        "a": [{
            "option": "Semmering, Koralpe",
            "correct": true
        }, {
            "option": "Schneeberg, Koralpe",
            "correct": false
        }, {
            "option": "Ötscher, Großglockner",
            "correct": false
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>Semmering, Koralpe</b><br>Die Züge passieren, auf insgesamt 470 km, viele neue Bahnhöfe und durchqueren mit hohen Geschwindigkeiten zwei Berge – den Semmering und die Koralpe.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>Semmering, Koralpe</b><br>Die Züge passieren, auf insgesamt 470 km, viele neue Bahnhöfe und durchqueren mit hohen Geschwindigkeiten zwei Berge – den Semmering und die Koralpe.</p>"
    }, {
        "q": "Welcher Hauptbahnhof wurde komplett neu errichtet?",
        "a": [{
            "option": "Graz Hauptbahnhof",
            "correct": false
        }, {
            "option": "Wien Hauptbahnhof",
            "correct": true
        }, {
            "option": "Klagenfurt Hauptbahnhof",
            "correct": false
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>Wien Hauptbahnhof</b><br>Ein Knotenpunkt im europäischen Verkehrsnetz: mit 109 Hektar Fläche ist das Gesamtprojekt Hauptbahnhof Wien\neines der größten Bauvorhaben der letzten Jahrzehnte in Stadt und Land. Es verbindet einen Bahnhofsneubau mit\nder Entwicklung eines ganzen Stadtviertels.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>Wien Hauptbahnhof</b><br>Ein Knotenpunkt im europäischen Verkehrsnetz: mit 109 Hektar Fläche ist das Gesamtprojekt Hauptbahnhof Wien\neines der größten Bauvorhaben der letzten Jahrzehnte in Stadt und Land. Es verbindet einen Bahnhofsneubau mit\nder Entwicklung eines ganzen Stadtviertels.</p>"
    }, {
        "q": "Wozu die Modernisierung und der Ausbau der Südstrecke?",
        "a": [{
            "option": "weil Süden die schönste Haupthimmelsrichtung ist",
            "correct": false
        }, {
            "option": "für mehr Mobilität, schnelleren Güterverkehr und die Umwelt",
            "correct": true
        }, {
            "option": "um schneller von Wien nach Salzburg zu kommen",
            "correct": false
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>für mehr Mobilität, schnelleren Güterverkehr und die Umwelt</b><br>Neben schnelleren Verbindungen für den Personenverkehr können Güterzüge auf einer begradigten Strecke länger und schwerer beladen sein, noch dazu mit nur einem Triebfahrzeug fahren. Das entlastet die Straße, schont die Umwelt und reduziert die Transportkosten wesentlich.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>für mehr Mobilität, schnelleren Güterverkehr und die Umwelt</b><br>Neben schnelleren Verbindungen für den Personenverkehr können Güterzüge auf einer begradigten Strecke länger und schwerer beladen sein, noch dazu mit nur einem Triebfahrzeug fahren. Das entlastet die Straße, schont die Umwelt und reduziert die Transportkosten wesentlich.</p>"
    }, {
        "q": "Warum „Mehr Schiene“ und „Weniger Straße“?",
        "a": [{
            "option": "aus Nostalgiegründen",
            "correct": false
        }, {
            "option": "aufgrund des derzeitigen Stahlpreises",
            "correct": false
        }, {
            "option": "für einen zukunftsorientierten\nPersonen- und Güterverkehr",
            "correct": true
        }],
        "correct": "<p class=\"p_24\">Richtig!</p><p><b>für einen zukunftsorientierten Personen- und Güterverkehr</b><br>Weil nur durch mehr Schienenverkehr die Voraussetzungen für einen zukunftsorientierten Personen- und Güterverkehr geschaffen werden – Mensch, Umwelt und Wirtschaft profitieren gleichermaßen davon.</p>",
        "incorrect": "<p class=\"p_24\">Falsch!</p><p><b>für einen zukunftsorientierten Personen- und Güterverkehr</b><br>Weil nur durch mehr Schienenverkehr die Voraussetzungen für einen zukunftsorientierten Personen- und Güterverkehr geschaffen werden – Mensch, Umwelt und Wirtschaft profitieren gleichermaßen davon.</p>"
    }]
};


$.fn.quizPlugin = function (options) {
	var pluginName = "quizPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
		// color: "#556b2f",
		// backgroundColor: "white"
	}, options);
	// Do something to each element here.


	return this.each(function () {
        var $elem = $(this);
        var checkAnswerText = "Auflösen";
		var nextQuestionText = "Nächste Frage &raquo;";
		var nextQuestionText = "Frage %current von %total";
		var preventUnansweredText = "Es muss eine Antwort ausgewählt werden.";
        var questionTemplateText = "%count. %text";
        var scoreTemplateText = "%score von %total Fragen richtig";
        var nameTemplateText = "<span>Quiz: </span>%name";
        var tryAgainText = "Neuer Versuch";

		if ($("html").attr("lang") == 'en') {
			checkAnswerText = "Check";
            nextQuestionText = "Next question &raquo;";
            nextQuestionText = "Question %current of %total";
            preventUnansweredText = "An answer must be selected.";
            questionTemplateText = "%count. %text";
            scoreTemplateText = "%score out of %total questions right";
            nameTemplateText = "<span>Quiz: </span>%name";
            tryAgainText = "New trial";
		}

        $elem.slickQuiz({
            numberOfQuestions : 5,
            randomSortQuestions : true,
            checkAnswerText:  'Auflösen',
            nextQuestionText: 'Nächste Frage &raquo;',
            backButtonText: '',
            completeQuizText: '',
            tryAgainText: '',
            questionCountText: 'Frage %current von %total',
            preventUnansweredText: 'Es muss eine Antwort ausgewählt werden.',
            questionTemplateText:  '%count. %text',
            scoreTemplateText: '%score von %total Fragen richtig',
            nameTemplateText:  '<span>Quiz: </span>%name',
            preventUnanswered : true,
            disableRanking : true,
            tryAgainText : "Neuer Versuch"
        });


	});


};