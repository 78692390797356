$.fn.lazyLoadFramesPlugin = function (options) {
	var pluginName = "lazyLoadFramesPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
	}, options);
	// Do something to each element here

    if('IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype &&
    'isIntersecting' in window.IntersectionObserverEntry.prototype) {
        var intersectionObserver = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if(entry.isIntersecting) {
                    var frame = entry.target;
                    
                    if (!frame.classList.contains('video-deactivated')) {
                        intersectionObserver.unobserve(frame);
                        frame.src = frame.dataset.framesrc;
                        frame.classList.remove('js-lazy-frame');

                        $(frame).on('load', function() {
                            $(frame).parent().addClass('md-iframe--loaded');
                        });
                    }

                }
            });
        }, {
            rootMargin: '300px'
        });

        return this.each(function () {
            intersectionObserver.observe(this);
        });
    } else {
        return this.each(function () {
            var frame = this;
            frame.src = frame.dataset.framesrc;
        });
    }

};

