
$.fn.piwikPlugin = function (options) {
	var pluginName = "piwikPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
		// color: "#556b2f",
		// backgroundColor: "white"
	}, options);
	// Do something to each element here.

	// trackEvent(category, action, [name], [value]);
	// Log an event with an event category (Videos, Music, Games...),
	// an event action (Play, Pause, Duration, Add Playlist, Downloaded, Clicked...),
	// and an optional event name and optional numeric value.
	return this.each(function () {
		var pageTitle = document.title || 'Kein Seitentitel';

		// Video
        var $videos = $('video');
		if (window._paq) {
			$videos.on('play', function(e) {
				_paq.push(['trackEvent', 'Video', 'Play', getVideoName($(this).attr('src'))]);
			})

			$videos.on('pause', function(e) {
				_paq.push(['trackEvent', 'Video', 'Pause', getVideoName($(this).attr('src'))]);
			})

			$videos.on('fullscreenchange webkitfullscreenchange mozfullscreenchange', function() {
				_paq.push(['trackEvent', 'Video', 'Resize', getVideoName($(this).attr('src'))]);
			});

			$videos.on('ended', function() {
				_paq.push(['trackEvent', 'Video', 'Finished', getVideoName($(this).attr('src'))]);
			});

			// Error Page
			var $errorPage = $('.js-error-page');
			if ($errorPage.length > 0) {
				if (document.location.href.indexOf("fehlerseiten") > -1) {
					var backlink = document.referrer.length > 0 ? document.referrer : "";
				} else {
					var backlink = document.location.href;
				}

				_paq.push(['trackEvent', 'Error', $errorPage.data('title'), backlink]);
			}

			// Contact Buttons
			var $contactButtons = $('.js-contact-button');
			if ($contactButtons.length > 0) {
				$contactButtons.on('click', function () {
					window._paq.push(['trackEvent', 'Phone Button', 'Click', pageTitle]);
				});
			}

			/*
			// Slider
			var $sliderLinks = $('.slider-link');
			if ($sliderLinks.length > 0) {
				$sliderLinks.on('click', function () {
					window._paq.push([
						'trackEvent',
						'Bühnen-Slider',
						'Slider-Element geklickt',
						pageTitle
							+ ' - ' + $(this).find('.slider-content-title').text()
							+ ' (' + ($sliderLinks.index(this) + 1) + '/' + $sliderLinks.length + ')'
					]);
				});
			}*/
		}
	});

	function getVideoName(videoSrc) {
		return videoSrc.replace(/^.*[\\\/]/, '')
	}

};
