$.fn.langDdNavPlugin = function (options) {
	var pluginName = "langDdNavPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.

	}, options);
	// Do something to each element here.


	return this.each(function () {
		var $nav = $(this);

		if (!$nav.data(pluginName)) {
			var countryList = $nav.find("ul.lang-dd-nav-list").first();
			countryList.attr("id","langNavList");
			countryList.attr("role",'menu');
			var $handler = $nav.find("a.lang-dd-nav-current").first();
			$handler.attr("aria-expanded","false");
			$handler.attr("aria-controls",countryList.attr("id"));
			var $handlerArrow = $handler.find("span:nth-child(2)");
			$handler.on('click',function (event) {
				event.preventDefault();
				event.stopPropagation();
				if ($handler.hasClass("open")) {
					closeMenu();
				} else {
					openMenu();

				}
			});
			var $lastlinkHandler = countryList.find("a").last();
			$lastlinkHandler.on('blur',function (event) {
				closeMenu();
			});

			function openMenu(){
				$handler.addClass("open");
				$handlerArrow.addClass("ic-arrow2-up");
				$handlerArrow.removeClass("ic-arrow2-down");
				$handler.attr("aria-expanded","true");
				$(".nav-bar-close").show();
				countryList.slideDown("fast", function () {

					}
				);
			}

			function closeMenu() {
				$handler.removeClass("open");
				$handlerArrow.addClass("ic-arrow2-down");
				$handlerArrow.removeClass("ic-arrow2-up");
				$handler.attr("aria-expanded","false");

				countryList.hide();
			}

			$(".nav-bar-close").on('mouseover', function (event) {
				closeMenu()
			})
			$(window).on('resize', function () {
				closeMenu();
			});

			$nav.data(pluginName, true);
		}
	});
};