/**
 * Created by l025351 on 29.02.2016.
 */

$.fn.hyperlinkIconsPlugin = function (options) {
	var pluginName = "hyperlinkIconsPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
		// color: "#556b2f",
		// backgroundColor: "white"
	}, options);
	// Do something to each element here.

	function checkForNoRelevantSiblings(node) {
		return node.parent().contents().filter(function () { // Only without surrounding text is hidden list icon
				return (this.nodeType == Node.ELEMENT_NODE && this.nodeName != 'BR') || (this.nodeType == Node.TEXT_NODE && !! (this.nodeValue).trim())
			}).length == 1;
	}

	return this.each(function () {
		var $content = $(this);

		if ($content.data("pluginName") != pluginName) {
			// setting up modules with anchors: placing the anchor that was around the image to be around the image and around the text

			// 4x1 Teaser Modules
			var $teasersToChange = $content.find("div.md-teaser-4 div.col-1");

			// $.each($teasersToChange, function (index, teaser) {
			// 	$teaser = $(teaser);
			// 	var $teaserAnchor = $teaser.find("a:has(>img)");
			// 	var $teaserText = $teaser.find("p:first-of-type");

			// 	if ($teaserAnchor != undefined && $teaserText != undefined) {
			// 		$teaserAnchor.append($teaserText);
			// 	}
			// });

			// Bild-Text Teaser Modules
			var $bildTextTeasersToChange = $content.find("div.md-img-text-teaser div.row");

			$.each($bildTextTeasersToChange, function (index, module) {
				$module = $(module);

				var $moduleImageAnchors = $module.find("div.col-1 > a");
				if ($moduleImageAnchors.length > 0) {
					var moduleImageAnchor = $moduleImageAnchors.get(0);
					if (moduleImageAnchor != undefined) {
						var $anchorClone = $(moduleImageAnchor.cloneNode());
						var $moduleImage = $module.find("div.col-1 > a > img");
						if ($moduleImage != undefined) {
							$moduleImage.unwrap();
							$module.wrap($anchorClone);
						}
					}
				} else {
					var $moduleHeaderAnchors = $module.find("div.col-1 > h3 > a");
					if ($moduleHeaderAnchors.length > 0) {
						var moduleHeaderAnchor = $moduleHeaderAnchors.get(0);
						var $moduleHeaderText = $moduleHeaderAnchors.contents();
						if ($moduleHeaderText != undefined) {
							var $anchorClone = $(moduleHeaderAnchor.cloneNode());
							$moduleHeaderText.unwrap();
							$module.wrap($anchorClone);
						}
					}
				}
			});

			// Publikation Modules
			var $publikationsToChange = $content.find("div.md-teaser-2 div.row div.col-2,div.md-text-teaser div.row div.col-2.md-teaser");

			$.each($publikationsToChange, function (index, publikation) {
				$publikation = $(publikation);

				var $publikationAnchor = $publikation.find("a");
				if ($publikationAnchor != undefined) {
					var publikationAnchor = $publikationAnchor.get(0);
					if (publikationAnchor != undefined) {
						var $publikationClone = $(publikationAnchor.cloneNode());
						var $publikationLinkText = $publikation.find("div.md-teaser-sublinks p");
						if ($publikationLinkText != undefined) {
							var publikationText = $publikationLinkText.text();
							$publikationLinkText.text("");
							$publikationClone.text(publikationText);
							$publikationLinkText.append($publikationClone);
						}
					}
				}
			});

			var $links = $content.find("a").not(":has(>img)");
			var height = $content.height();

			$.each($links, function (index, link) {
				$link = $(link);
				if ($link.attr("href")) {
					var href = $link.attr("href");
					if (/^mailto:/.test(href)) { // Mailto-Link
						$link.prepend('<span aria-hidden="true" class="ic-mail1 link-icon"></span>');
					} else if (/^https?:\/\//.test(href)) { // Download-Link external
						if(!/\.(html|xhtml|shtml|htm|jsp|asp|aspx|php|do|wxe)?(\?.*)?$/.test(href) && /^https?:\/\/.+\/.+?\.[a-zA-Z0-9]+(\?.*)?$/.test(href)) {
							if ($link.parent().is("li")) { // Download link without surrounding text in list or Infobox hyperlinks
								if (checkForNoRelevantSiblings($link)) { // Only without surrounding text is hidden list icon
									$link.parent().parent().addClass("link-icon");
								}
								$link.prepend('<span aria-hidden="true" class="ic-download link-icon"></span>');
							} else { // Download-Link überall
								$link.prepend('<span aria-hidden="true" class="ic-download link-icon"></span>');
							}
						} else if ($link.parent().is("li") && $link.parent().parent().is("ul:not(.karten-legende):not(.sharelinks):not(.teaser-slider-list):not(.sitemap):not(.sitemap__sublevel):not(.no-list-icons)") && // Document link without surrounding text in list or infobox hyperlinks
							checkForNoRelevantSiblings($link)) { // Only without surrounding text
							$link.prepend('<span aria-hidden="true" class="ic-arrow2-right link-icon"></span>');
							$link.parent().parent().addClass("link-icon");
						}
					} else if (!/\.(html|xhtml|shtml|htm|jsp|asp|aspx|php|do|wxe)?(\?.*)?$/.test(href) && /\.[a-zA-Z0-9]+(\?.*)?$/.test(href)) { // Download link intern
						if ($link.parent().is("li")) { // Download link in list or Infobox hyperlinks
							if (checkForNoRelevantSiblings($link)) { // Only without surrounding text is hidden list icon
								$link.parent().parent().addClass("link-icon");
							}
							$link.prepend('<span aria-hidden="true" class="ic-download link-icon"></span>');
						} else if (!$link.parent().is("td.md-dms-name") && !$link.parent().is("div.md.md-img-text-teaser")) { // Download-Link überall außer DMS-Tabelle
							$link.prepend('<span aria-hidden="true" class="ic-download link-icon"></span>');
						}
					} else if ($link.parent().is("li") && $link.parent().parent().is("ul:not(.karten-legende):not(.sharelinks):not(.teaser-slider-list):not(.sitemap):not(.sitemap__sublevel):not(.no-list-icons)") && // Document link without surrounding text in list or infobox hyperlinks
						checkForNoRelevantSiblings($link)) { // Only without surrounding text
						$link.prepend('<span aria-hidden="true" class="ic-arrow2-right link-icon"></span>');
						$link.parent().parent().addClass("link-icon");
					}

				}
			});

			$content.data("pluginName", pluginName);
			$content.data(pluginName, $content);
		}
	});
};
