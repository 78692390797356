$.fn.imageMapPlugin = function (options) {

    var pluginName = 'imageMapPlugin';

    // Default options
    var settings = $.extend({
        // These are the defaults.
    }, options);

    // Do something to each element here.
    return this.each(function () {
        var self = this;
        var $links = $(this).find('.js-show-image-map-area-content');

        $.each($links, function (index, link) {
            $(link).on('click', function (e) {
                e.preventDefault();

                var id = this.href.split('#')[1];

                $('.js-image-map-area-content').hide();
                $('#' + id).show(); 
            });
        });
    });

};
